.ht-ratio {
    position: relative;
    overflow: hidden;
}
.ht-ratio:before {
    content: '';
    display: block;
    padding-bottom: 70%;
}
.ht-ratio--55:before {
    padding-bottom: 55%;
}
.ht-ratio--75:before {
    padding-bottom: 75%;
}
.ht-ratio>.ht-ratio__img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: -ms-flex;
    display: -webkit-flex;
    display: flex;
    -moz-align-items: center;
    -webkit-align-items: center;
    align-items: center;
}
.ht-mrt15 {
    margin-top: 15px;
}
.ht-mrb30 {
    margin-bottom: 30px;
}
/* Style chung cho header */
.hd-logo {
    line-height: 0;
}
.hd-menu h2 {
    font-size: inherit;
}
.hd-btn__menu i {
    -webkit-transition: 0.3s ease;
    -o-transition: 0.3s ease;
    transition: 0.3s ease;
}
.hd-btn__menu i:first-child {
    opacity: 1;
    visibility: visible;
    -webkit-transform: translate(-50%, -50%) rotate(0deg) scale(1);
    transform: translate(-50%, -50%) rotate(0deg) scale(1);
}
.hd-btn__menu i:last-child {
    opacity: 0;
    visibility: hidden;
    -webkit-transform: translate(-50%, -50%) rotate(-90deg) scale(0);
    transform: translate(-50%, -50%) rotate(-90deg) scale(0);
}
.hd-btn__menu.is-active i:first-child {
    opacity: 0;
    visibility: hidden;
    -webkit-transform: translate(-50%, -50%) rotate(-90deg) scale(0);
    transform: translate(-50%, -50%) rotate(-90deg) scale(0);
}
.hd-btn__menu.is-active i:last-child {
    opacity: 1;
    visibility: visible;
    -webkit-transform: translate(-50%, -50%) rotate(0deg) scale(1);
    transform: translate(-50%, -50%) rotate(0deg) scale(1);
}
[class*="s-header-2"] .s-header__top {
    position: relative;
    height: 35px;
}
[class*="s-header-2"] .s-header__top .container {
    height: 100%;
}
[class*="s-header-2"] .s-header__top ul>li {
    display: inline-block;
    color: #111;
}
[class*="s-header-2"] .s-header__top ul.hd-contact {
    float: left;
}
[class*="s-header-2"] .s-header__top ul.hd-contact span.fa {
    padding-left: 23px;
    color: #fff;
}
[class*="s-header-2"] .s-header__top ul.hd-contact span.fa:before {
    font-size: 13px;
    position: absolute;
    width: 18px;
    height: 18px;
    line-height: 18px;
    background-color: #fff;
    text-align: center;
    border-radius: 20%;
    color: #FDB813;
}
[class*="s-header-2"] .s-header__top ul.hd-contact span.fa-phone:before {
    font-size: 10px;
}
[class*="s-header-2"] .s-header__top ul.hd-contact li:not(:last-child) {
    margin-right: 15px;
}
[class*="s-header-2"] .s-header__top .hd-search {
    width: 185px;
    border: 1px solid #fff;
    border-radius: 5px;
}
[class*="s-header-2"] .s-header__top .hd-search form {
    position: relative;
}
[class*="s-header-2"] .s-header__top .hd-search form>input {
    background: transparent;
    border: medium none;
    height: 24px;
    font-size: 12px;
    color: #fff;
    border-radius: 3px;
    width: calc(100% - 30px);
    padding: 0 7px 0 10px;
}
[class*="s-header-2"] .s-header__top .hd-search form>input::-webkit-input-placeholder {
    color: inherit;
}
[class*="s-header-2"] .s-header__top .hd-search form>input::-moz-input-placeholder {
    color: inherit;
}
[class*="s-header-2"] .s-header__top .hd-search form>input::-ms-input-placeholder {
    color: inherit;
}
[class*="s-header-2"] .s-header__top .hd-search form>input:focus {
    outline: none;
}
[class*="s-header-2"] .s-header__top .hd-search form>button {
    border: medium none;
    color: #FDB813;
    display: block;
    height: 22px;
    margin: 1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    right: 0;
    text-align: center;
    top: 0;
    width: 22px;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-radius: 3px;
    font-size: 12px;
    background-color: #fff;
}
[class*="s-header-2"] .s-header__top .hd-search form>button:hover {
    opacity: 0.5;
}
[class*="s-header-2"] .s-header__bot .hd-logo {
    font-size: 14px;
}
[class*="s-header-2"] .s-header__bot .hd-logo img {
    height: 6em;
    -webkit-transition: all .25s ease-in-out;
    transition: all .25s ease-in-out;
}

[class*="s-header-2"] .s-header__bot .hd-menu__list {
    display: inline-block;
}

[class*="s-header-2"] .s-header__bot .hd-menu__list:before,
[class*="s-header-2"] .s-header__bot .hd-menu__list:after {
    content: " ";
    display: table;
}

[class*="s-header-2"] .s-header__bot .hd-menu__list:after {
    clear: both;
}

[class*="s-header-2"] .s-header__bot .hd-menu__list>li {
    float: left;
}

[class*="s-header-2"] .s-header__bot .hd-menu__list>li:not(:last-child) {
    margin-right: 15px;
}

[class*="s-header-2"] .s-header__bot .hd-menu__list>li>a {
    font-family: Arsenal, Arial, sans-serif;
    font-size: 15px;
    display: block;
    line-height: 34px;
    text-transform: uppercase;
    -webkit-transition: none;
    -o-transition: none;
    transition: none;
}

[class*="s-header-2"] .s-header__bot .hd-menu__list>li>.sub-menu {
    position: absolute;
    opacity: 0;
    visibility: hidden;
}

[class*="s-header-2"] .s-header__bot .hd-menu__list>li.is-focus>.sub-menu {
    opacity: 1;
    visibility: visible;
}

[class*="s-header-2"] .s-header__bot .hd-menu .hd-btn__menu {
    font-size: 23px;
}

[class*="s-header-2"] .s-header__bot .hd-menu .hd-btn__menu i {
    font-size: 15px;
}

/*
* s-header-2b
*/

.s-header-2b .s-header {
    position: relative;
    z-index: 1;
    -webkit-box-shadow: 0px 2px 5px #999;
    box-shadow: 0px 2px 5px #999;
    position: relative;
    z-index: 111;
}
.s-header-2b .s-header__top {
    background-color: #00bbff;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
.s-header-2b .s-header__bot {
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
}
.s-header-2b .s-header__bot:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.5);
}
.s-header-2b .s-header__bot .container {
    position: relative;
    z-index: 1;
}
.s-header-2b .s-header__bot .hd-logo {
    margin: 10px 0;
    position: relative;
}
.s-header-2b .s-header__bot .hd-logo__name {
    position: absolute;
    bottom: -17px;
    right: 0;
    font-size: 18px;
    letter-spacing: 1.2px;
    line-height: 1;
}
.s-header-2b .s-header__bot .hd-menu {
    display: -ms-flex;
    display: -webkit-flex;
    display: flex;
    -moz-align-items: center;
    -webkit-align-items: center;
    align-items: center;
}
.s-header-2b .s-header__bot .hd-menu__list>li>a {
    color: #444444;
}
.s-header-2b .s-header__bot .hd-menu .hd-btn__menu {
    font-size: 28px;
}
.s-header-2b .s-header__bot .hd-menu .hd-btn__menu:first-child {
    margin-left: 20px;
}
.s-header-2b .s-header__bot .hd-menu .hd-btn__menu i {
    color: #FDB813;
}
.s-header-2b [class*="c-icon-border"] {
    border: 1px solid #FDB813;
}
.s-header-2b--fixed:before {
    content: '';
    display: block;
    padding-bottom: 101px;
}
.s-header-2b--fixed .s-header {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;
}
.s-header-2b--fixed .s-header__bot .hd-logo {
    margin: 5px 0;
}
.s-header-2b--fixed .s-header__bot .hd-logo img {
    height: 4em;
}
.s-header-2b--fixed .s-header__bot .hd-logo__name {
    display: none;
}
.s-header-2b a.cart {
    position: relative;
    margin-left: 20px;
    display: inline-block;
}
.s-header-2b a.cart i {
    font-size: 25px;
    color: #999999;
    padding-right: 10px;
}
.s-header-2b a.cart .bage {
    position: absolute;
    top: -6px;
    right: 0;
    width: 18px;
    height: 18px;
    background-color: #ffbf00;
    font-size: 12px;
    color: #FFFFFF;
    border-radius: 50%;
    text-align: center;
    line-height: 20px;
}
.s-header-2b a.cart:hover {
    color: #ffbf00;
}

/* Style chung cho footer */

[class*="s-footer-"] {
    margin-top: 15px;
}

.s-footer__top-title {
    margin: 10px 0 15px;
    color: #FFFFFF;
}
.s-footer__top-title>i {
    color: #CCCCCC;
    margin-right: 5px;
}
.s-footer__top-title h5 {
    display: inline;
    font-weight: bold;
    text-transform: uppercase;
}
.s-footer__top-title h5:after {
    content: '';
    display: block;
    width: 75%;
    height: 1px;
    margin-top: 5px;
    background-color: rgba(255, 0, 0, 0);
    background-image: -webkit-linear-gradient(right, rgba(255, 0, 0, 0), #CCCCCC 100%);
    background-image: linear-gradient(to left, rgba(255, 0, 0, 0), #CCCCCC 100%);
}
.s-footer__top-menu li {
    display: inline-block;
    font-size: 13px;
    margin-bottom: 10px;
    color: #FFFFFF;
    width: calc((100% / 2) - 12px);
    margin-right: 10px;
    vertical-align: top;
}
.s-footer__top-menu li.line {
    width: 100%;
    margin-right: 0;
}
.s-footer__top-menu li>h1 {
    font-size: inherit;
}
.s-footer__top-menu li a {
    color: inherit;
    display: inline-block;
    -webkit-transition: 0.3s all ease-in-out;
    -o-transition: 0.3s all ease-in-out;
    transition: 0.3s all ease-in-out;
}
.s-footer__top-menu li a>span:before {
    content: "\f105";
    font: normal normal normal 13px/1 FontAwesome;
    margin-right: 5px;
    color: #CCCCCC;
}
.s-footer__top-menu li a:hover {
    color: #FFBF00;
}
.s-footer__top-menu li>span {
    line-height: 20px;
    margin-right: 20px;
}
.s-footer__top-menu li>span:last-child {
    margin-right: 0;
}
.s-footer__top-menu li>span.fa:before {
    text-align: center;
    width: 15px;
    color: #CCCCCC;
}
.s-footer__bot-copyright, .s-footer__bot-developed {
    float: left;
    font-size: 12px;
    line-height: 40px;
    color: #FFFFFF;
}
.s-footer__bot-developed {
    float: right;
}
.s-footer__bot-developed a {
    display: inline-block;
    margin-left: 5px;
}
.s-footer__bot-developed a img {
    -webkit-transform: translateY(1px);
    transform: translateY(1px);
}
.s-footer__backtop a {
    color: #FFFFFF;
}
.s-footer__backtop a:first-child {
    position: fixed;
    bottom: 10px;
    left: 10px;
    z-index: 111111;
    width: 40px;
    height: 40px;
    background-color: #FFBF00;
}
.s-footer__backtop a:first-child i {
    font-size: 20px;
}
.s-footer__backtop a:last-child {
    position: fixed;
    z-index: 111111;
    font-size: 20px;
    background-color: #FFBF00;
    bottom: calc(60% - 1em);
    opacity: 0;
    right: 10px;
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
}
.s-footer__backtop a:last-child.is-show {
    opacity: 1;
    bottom: 15px;
}


/* c-animate-loopFlicker */

.c-animate-loopFlicker {
    -webkit-animation: loopFlicker 1s infinite;
    -o-animation: loopFlicker 1s infinite;
    animation: loopFlicker 1s infinite;
}

@-webkit-keyframes loopFlicker {
    0% {
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
    }
    20% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    40% {
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
    }
    60% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
}
@-moz-keyframes loopFlicker {
    0% {
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
    }
    20% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    40% {
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
    }
    60% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
}
@-o-keyframes loopFlicker {
    0% {
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
    }
    20% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    40% {
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
    }
    60% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
}
@keyframes loopFlicker {
    0% {
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
    }
    20% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    40% {
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
    }
    60% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
}

/* .s-footer-3 */

.s-footer-3 .align-items--center {
    height: 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}
.s-footer-3 .s-footer__top {
    padding: 20px 0;
    background-color: #00BBFF;
    background-color: rgba(25, 118, 210, 0.85);
}
.s-footer-3 .s-footer__top .ft-ellipsis {
    margin-top: 3px;
    display: block;
    overflow: hidden;
    font-size: 1.4rem;
    line-height: 1.43em;
    max-height: 8.58em;
}
.s-footer-3 .s-footer__bot {
    background-color: #00BBFF;
}


/* Style chung cho footer */


/*
* s-footer-4
*/

.s-footer-4 {
    background-color: #F6F6F6;
    background-position: 20% 10%;
    background-size: 50%;
    background-repeat: no-repeat;
}
.s-footer-4 .align-items--center {
    height: 100%;
}
.s-footer-4 .s-footer__pre {
    padding-top: 30px;
    background-color: #F6F6F6;
    background-color: rgba(246, 246, 246, 0.8);
    position: relative;
}
.s-footer-4 .s-footer__pre-title {
    text-transform: uppercase;
    color: #222222;
    margin-bottom: 5px;
}
.s-footer-4 .s-footer__pre-menu {
    list-style: initial;
    margin-left: 25px;
    color: #999;
}
.s-footer-4 .s-footer__pre-menu a {
    color: #777;
    padding: 5px 0px;
    display: block;
}
.s-footer-4 .s-footer__pre-menu a:hover {
    color: #FFBF00;
}
.s-footer-4 .s-footer__pre-viewall {
    display: block;
    font-size: 12px;
    margin: 10px 0 30px;
    color: #00BBFF;
}
.s-footer-4 .s-footer__pre-viewall:hover {
    color: #FFBF00;
}
.s-footer-4 .s-footer__pre-mail>p {
    font-weight: 600;
    margin-top: 20px;
}
.s-footer-4 .s-footer__pre-mail>form {
    position: relative;
}
.s-footer-4 .s-footer__pre-mail>form input {
    padding-right: 35px;
}
.s-footer-4 .s-footer__pre-mail>form>button {
    position: absolute;
    top: 50%;
    right: 2px;
    z-index: 2;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}
.s-footer-4 .s-footer__top {
    padding: 30px 0;
    -webkit-box-shadow: 0px 0px 50px #666666;
    box-shadow: 0 -15px 50px #666666;
    background-color: #00BBFF;
    background-color: rgba(25, 118, 210, 0.05);
}
.s-footer-4 .s-footer__top-title>i {
    color: #999999;
}
.s-footer-4 .s-footer__top-title h5 {
    color: #222222;
}
.s-footer-4 .s-footer__top-title h5:after {
    background-color: rgba(255, 0, 0, 0);
    background-image: -webkit-linear-gradient(right, rgba(255, 0, 0, 0), #999999 100%);
    background-image: linear-gradient(to left, rgba(255, 0, 0, 0), #999999 100%);
}
.s-footer-4 .s-footer__top-menu li {
    color: #222222;
}
.s-footer-4 .s-footer__top-menu li span:before {
    color: #999999;
}
.s-footer-4 .s-footer__top-pay>div:first-child {
    border-bottom: 1px dashed #999999;
    padding-bottom: 10px;
    margin-bottom: 10px;
}
.s-footer-4 .s-footer__top-pay>div:first-child img {
    width: 46%;
}
.s-footer-4 .s-footer__bot {
    background-color: #00bbff;
}

.col-7.col-md-2 .s-footer__top-menu li {
    width: 100%;
}

/* Style chung cho blocks products */

[class*="b-product-"] {
    background-color: #FFFFFF;
    margin: 15px 0px;
    overflow: hidden;
}
[class*="b-product-"] .price-discount {
    font-size: 16px;
    color: #ffbf00;
}
[class*="b-product-"] .price-market {
    font-size: 12px;
    color: #999999;
    text-decoration: line-through;
}

.b-product__img {
    margin-bottom: 10px;
    position: relative;
    overflow: hidden;
}
.b-product__img::before {
    content: '';
    display: block;
    padding-bottom: 75%;
}
.b-product__img>a {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: -ms-flex;
    display: -webkit-flex;
    display: flex;
    -moz-align-items: center;
    -webkit-align-items: center;
    align-items: center;
}
.b-product__img>a:after, .b-product__img>a:before {
    content: initial !important;
}
.b-product__img>a img {
    width: 100%;
}
.b-product__content-title {
    font-size: 15px;
}
.b-product__content-title>a {
    -webkit-transition: 0.3s all ease-in-out;
    -o-transition: 0.3s all ease-in-out;
    transition: 0.3s all ease-in-out;
}
.b-product__content-title>a:hover {
    color: #FFD351;
}


/* b-product-2 */

[class*="b-product-2"] .b-product__title {
    font-size: 15px;
    text-align: center;
    border-top: 1px solid #EEE;
    margin: 20px 10px 0;
    padding: 10px;
}
[class*="b-product-2"] .b-product__title>a {
    font-size: 1.6rem;
}
[class*="b-product-2"] .b-product__title>a:hover {
    color: #FFBF00;
}


/* b-product-3 */

[class*="b-product-3"] .b-product__content {
    position: relative;
    margin: 10px 0 5px;
}
[class*="b-product-3"] .b-product__content-title {
    margin-bottom: 5px;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    min-height: 42px;
}
[class*="b-product-3"] .b-product__content-title>a:hover {
    color: #ffbf00;
}
[class*="b-product-3"] .b-product__content-divider {
    width: 100%;
    height: 1px;
    background: #e2e2e2;
    position: relative;
}
[class*="b-product-3"] .b-product__content-divider span {
    background-color: #ffbf00;
    position: relative;
    float: right;
    width: 30px;
    padding-top: 3px;
    right: 0;
    color: #FFFFFF;
    font-size: 9.5px;
    text-align: center;
    font-weight: bold;
}
[class*="b-product-3"] .b-product__content-divider span:after {
    border-color: #ffbf00 transparent #ffbf00;
    border-style: solid;
    border-width: 10px 15px 0px 15px;
    content: '';
    left: 0;
    bottom: -10px;
    position: absolute;
}
[class*="b-product-3"] .b-product__content-cate {
    font-size: 1.2rem;
    font-weight: bold;
    color: #6c6c6c;
    letter-spacing: 1px;
    margin-top: 10px;
    width: calc(100% - 35px);
}
[class*="b-product-3"] .b-product__content-cate>a:hover {
    color: #ffbf00;
}
[class*="b-product-3"] .b-product__content-price {
    margin-top: 10px;
}

/* Style chung cho news detail S-DNEWS */

[class*="s-dnews-"] {
    margin-bottom: 15px;
}

.s-dnews__title {
    color: #00BBFF;
    font-size: 2.2rem;
    font-weight: bold;
    line-height: 1.19em;
    margin-bottom: 15px;
}
.s-dnews__source {
    display: -ms-flex;
    display: -webkit-flex;
    display: flex;
    -moz-align-items: center;
    -webkit-align-items: center;
    align-items: center;
    margin-bottom: 20px;
}
.s-dnews__source:after, .s-dnews__source:before {
    content: initial !important;
}
.s-dnews__source li {
    display: inline-block;
    font-size: 12px;
    margin-right: 20px;
    line-height: 0;
}
.s-dnews__source li span.fa {
    text-transform: uppercase;
    line-height: 1.2;
}
.s-dnews__source li span.fa:before {
    color: #FFBF00;
}
.s-dnews__source li a:hover {
    color: #FFBF00;
}
.s-dnews__desc {
    font-weight: bold;
    font-size: 1.5rem;
    line-height: 1.34em;
    margin-bottom: 20px;
    position: relative;
    text-align: justify;
}
.s-dnews__detail {
    margin-bottom: 30px;
}
.s-dnews__detail a {
    color: blue;
    background: none;
}
.s-dnews__detail a:hover {
    color: #FFBF00;
}
.s-dnews__detail ul,
.s-dnews__detail ol {
    list-style: initial;
    padding-left: 15px;
}
.s-dnews__detail img {
    height: auto !important;
}
.s-dnews__detail table {
    max-width: 100%;
}
.s-dnews__detail table p {
    margin-bottom: 0;
}
.s-dnews__detail table td, .s-dnews__detail table th {
    padding: 0 5px;
}
.s-dnews__other {
    margin: 30px 0 0 40px;
}

/* s-sidebar-2 */

[class*="s-sidebar-2"] {
    background-color: #D3DAE3;
    -webkit-box-shadow: 2px -1px 5px rgba(35, 70, 113, 0.4);
    box-shadow: 2px -1px 5px rgba(35, 70, 113, 0.4);
}
[class*="s-sidebar-2"] .s-sidebar__header {
    font-size: 15px;
    color: #FFFFFF;
    position: relative;
    padding: 0 10px;
    display: block;
    border-bottom: 10px solid transparent;
    border-left: 20px solid #FFBF00;
    border-top: 10px solid transparent;
    text-transform: uppercase;
    background-color: #00BBFF;
}
[class*="s-sidebar-2"] .s-sidebar__header:before {
    content: "\f0da";
    font-family: FontAwesome;
    position: absolute;
    left: -13px;
    color: #FFFFFF;
}
[class*="s-sidebar-2"] .s-sidebar__cate {
    background-color: #D3DAE3;
}
[class*="s-sidebar-2"] .s-sidebar__cate li {
    margin-top: 1px;
}
[class*="s-sidebar-2"] .s-sidebar__cate li a {
    font-size: 13px;
    padding: 15px;
    background-color: rgba(35, 70, 113, 0.2);
    color: #333333;
    text-transform: uppercase;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-transition: all 0.25s ease-in-out;
    -o-transition: all 0.25s ease-in-out;
    transition: all 0.25s ease-in-out;
}
[class*="s-sidebar-2"] .s-sidebar__cate li a:before {
    content: "\f1ce";
    font-family: FontAwesome;
    font-size: 0.62em;
    padding-top: 0.5em;
    color: #234671;
}
[class*="s-sidebar-2"] .s-sidebar__cate li a h2 {
    margin-left: 7px;
    font-size: inherit;
}
[class*="s-sidebar-2"] .s-sidebar__cate li a:hover {
    background-color: #00BBFF;
    color: #FFFFFF;
}
[class*="s-sidebar-2"] .s-sidebar__cate li a:hover:before {
    color: #FFBF00;
}


/* s-sidebar-2b */

.s-sidebar-2b .s-sidebar__news {
    padding: 15px;
}
.s-sidebar-2b .s-sidebar__news li {
    border-bottom: 1px solid #EEEEEE;
    padding-bottom: 15px;
    margin-bottom: 15px;
}
.s-sidebar-2b .s-sidebar__news li:last-child {
    border-bottom: none;
    padding-bottom: 0;
    margin-bottom: 0;
}
.s-sidebar-2b .s-sidebar__news li a {
    display: block;
    padding-right: 10px;
    -webkit-transition: 0.3s all ease-in-out;
    -o-transition: 0.3s all ease-in-out;
    transition: 0.3s all ease-in-out;
}
.s-sidebar-2b .s-sidebar__news li a:before, .s-sidebar-2b .s-sidebar__news li a:after {
    content: " ";
    display: table;
}
.s-sidebar-2b .s-sidebar__news li a:after {
    clear: both;
}
.s-sidebar-2b .s-sidebar__news li a:hover {
    color: #FFBF00;
}
.s-sidebar-2b .s-sidebar__news-image {
    float: left;
    width: 90px;
    line-height: 0;
    margin-right: 12px;
}

/* s-sidebar-3 */

[class*="s-sidebar-3"] .s-sidebar__header {
    font-size: 15px;
    padding: 10px 15px;
    background-color: #00BBFF;
    text-transform: uppercase;
    color: #FFFFFF;
}
[class*="s-sidebar-3"] .s-sidebar__cate {
    border: 1px solid #EEEEEE;
}
[class*="s-sidebar-3"] .s-sidebar__cate li a {
    border-bottom: 1px solid #EEEEEE;
    padding: 13px 15px;
    -webkit-transition: 0.3s all ease-in-out;
    -o-transition: 0.3s all ease-in-out;
    transition: 0.3s all ease-in-out;
}


/* s-sidebar-3a */
.s-sidebar-3a {
    margin-bottom: 15px;
}
.s-sidebar-3a .s-sidebar__cate li>a {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    font-size: 13px;
}
.s-sidebar-3a .s-sidebar__cate li>a:before {
    content: "\f105";
    font-family: FontAwesome;
    font-size: 0.85em;
    color: #999999;
}
.s-sidebar-3a .s-sidebar__cate li>a h2 {
    font-size: inherit;
    display: inline;
    margin-left: 7px;
    font-weight: normal;
    text-transform: uppercase;
}
.s-sidebar-3a .s-sidebar__cate li>a:hover {
    color: #FFBF00;
}
.s-sidebar-3a .s-sidebar__cate li>ul {
    display: none;
}
.s-sidebar-3a .s-sidebar__cate li>ul li a {
    padding: 13px 26px;
    color: #222222;
}
.s-sidebar-3a .s-sidebar__cate li>ul li a:before {
    content: "\f10c";
    font-size: 0.54em;
    position: relative;
    top: 0.39em;
}
.s-sidebar-3a .s-sidebar__cate li>ul li a h2 {
    font-weight: normal;
}
.s-sidebar-3a .s-sidebar__cate li.is-active>a {
    color: #FFBF00;
}
.s-sidebar-3a .s-sidebar__cate li.is-active>ul {
    display: block;
}

/* s-breadcrumb-1 */

.s-breadcrumb-1 {
    font-size: 1.3rem;
    margin-bottom: 30px;
}
.s-breadcrumb-1 ul {
    display: -ms-flex;
    display: -webkit-flex;
    display: flex;
    -moz-align-items: center;
    -webkit-align-items: center;
    align-items: center;
}
.s-breadcrumb-1 ul:after, .s-breadcrumb-1 ul:before {
    content: initial !important;
}
.s-breadcrumb-1 ul li {
    margin-left: 0.54em;
}
.s-breadcrumb-1 ul li i {
    font-size: 0.85em;
}
.s-breadcrumb-1 ul li i.fa-angle-double-right {
    color: #888;
}
.s-breadcrumb-1 ul li a {
    font-weight: bold;
    color: inherit;
}
.s-breadcrumb-1 ul li a:hover {
    color: #FFD351;
}
.s-breadcrumb-1 ul li:first-child {
    margin-left: 0;
}
.s-breadcrumb-1 ul li:first-child a>i {
    font-size: 1.54em;
}

/* Style chung s-pagination */

[class*="s-pagination-"] {
    padding: 25px 0;
    font-size: 12px;
}
[class*="s-pagination-"]:before, [class*="s-pagination-"]:after {
    content: " ";
    display: table;
}
[class*="s-pagination-"]:after {
    clear: both;
}
[class*="s-pagination-"]>li {
    display: inline-block;
    margin: 0 2px;
    border: 1px solid #DDDDDD;
    width: 2.5em;
    height: 2.5em;
    line-height: 2.34em;
    -webkit-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}
[class*="s-pagination-"]>li>a {
    display: block;
    color: #999999;
    font-weight: 600;
    text-align: center;
}
[class*="s-pagination-"]>li:hover {
    background-color: #EEEEEE;
    color: #999999;
}
[class*="s-pagination-"]>li.active {
    background-color: #00BBFF;
    border: 1px solid #00BBFF;
}
[class*="s-pagination-"]>li.active>a {
    color: #FFFFFF;
}


/*s-pagination-1 */

.s-pagination-1>li {
    border-radius: 3px;
}

/* Style chung cho blocks news */

[class*="b-news-"] {
    background-color: #FFFFFF;
    overflow: hidden;
    margin: 0 5px 20px;
}

.b-news__img {
    margin-bottom: 10px;
    position: relative;
    overflow: hidden;
}
.b-news__img::before {
    content: '';
    display: block;
    padding-bottom: 75%;
}
.b-news__img>a {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: -ms-flex;
    display: -webkit-flex;
    display: flex;
    -moz-align-items: center;
    -webkit-align-items: center;
    align-items: center;
}
.b-news__img>a:after, .b-news__img>a:before {
    content: initial !important;
}
.b-news__img>a img {
    width: 100%;
}
.b-news__content-title {
    font-size: 1.6rem;
    line-height: 1.13em;
    margin-bottom: 10px;
    font-weight: bold;
}
.b-news__content-title>a {
    -webkit-transition: 0.3s color ease-in-out;
    -o-transition: 0.3s color ease-in-out;
    transition: 0.3s color ease-in-out;
}
.b-news__content-title>a:hover {
    color: #FFD351;
}
.b-news__content-desc {
    text-align: justify;
}


/*header-2*/

[class*="s-header-2"] .s-header__bot .hd-menu__list>li>a {
    display: block;
    line-height: 34px;
    text-transform: uppercase;
    -webkit-transition: none;
    -o-transition: none;
    transition: none;
}
[class*="s-header-2"] .s-header__bot .hd-menu__list>li>.sub-menu {
    position: absolute;
    opacity: 0;
    visibility: hidden;
}
[class*="s-header-2"] .s-header__bot .hd-menu__list>li.is-focus>.sub-menu {
    opacity: 1;
    visibility: visible;
}

[class*="s-header-2"] .s-header__bot .hd-menu .hd-btn__menu {
    font-size: 18px;
}
[class*="s-header-2"] .s-header__bot .hd-menu .hd-btn__menu i {
    font-size: 15px;
}


/*
* s-header-2b
*/

.s-header-2b .s-header__bot .hd-logo {
    margin: 10px 0;
}
.s-header-2b .s-header__bot .hd-menu__list>li>.sub-menu {
    top: 110%;
    background-color: #FFFFFF;
    -webkit-box-shadow: 0px 5px 10px #ccc;
    box-shadow: 0px 5px 10px #ccc;
    border-radius: 0px 0px 5px 5px;
    z-index: 2000;
    -webkit-transition: all 0.33s ease;
    -o-transition: all 0.33s ease;
    transition: all 0.33s ease;
}
.s-header-2b .s-header__bot .hd-menu__list>li>.sub-menu>li a {
    font-size: 14px;
    position: relative;
    display: block;
    text-transform: none;
    padding: 10px 33px;
    color: #000000;
    -webkit-transition: 0.3s all ease;
    transition: 0.3s all ease;
    border-bottom: 1px solid #eee;
    -webkit-transition: 0.3s all ease;
    -o-transition: 0.3s all ease;
    transition: 0.3s all ease;
}
.s-header-2b .s-header__bot .hd-menu__list>li>.sub-menu>li a:before {
    content: "\f105";
    font-family: FontAwesome;
    font-size: 0.86em;
    color: inherit;
    position: absolute;
    top: 0.67em;
    left: 0;
    pointer-events: none;
    opacity: 0;
    -webkit-transform: translateX(-5px);
    transform: translateX(-5px);
    -webkit-transition: 0.3s all ease;
    -o-transition: 0.3s all ease;
    transition: 0.3s all ease;
}
.s-header-2b .s-header__bot .hd-menu__list>li>.sub-menu>li a:hover:before {
    opacity: 1;
    -webkit-transform: translateX(20px) translateY(3px);
    transform: translateX(20px) translateY(3px);
}


/*custom css*/

.container>.c-headline-2 h1.c-headline__name {
    margin-top: 30px;
}
[class*="c-headline-"] i {
    color: #ffbf00;
}
.ht-page {
    margin-bottom: 40px;
}
.fa-info-circle:before {
    padding-right: 10px;
}
/* c-button-4 */

.c-button-4 {
    background-color: #FFD351;
    text-align: center;
    display: block;
    font-size: 1.4rem;
    padding: 0.58em 1em 0.58em 1em;
}
.c-button-4 span {
    color: #FFFFFF;
    -webkit-transition: all 0.3s ease-out 0s;
    -o-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
    position: relative;
    left: 0;
    text-transform: uppercase;
}
.c-button-4 span.fa:before {
    font-size: 0.93em;
    color: #FFFFFF;
}
.c-button-4:hover span {
    left: 0.36em;
}
/* c-select */
.c-select {
  position: relative;
    margin-top: 6px;
}
.c-select.form-line > .c-icons {
  width: 12px;
}
.c-select.form-line > .form__input {
  padding: 0;
}
.c-select > .form__input {
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 36px;
  padding: 0 30px 0 12px;
}
.c-select > .c-icons {
  font-size: 15px;
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 30px;
}
.c-select > .c-icons > i {
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}
.c-select__list {
  position: absolute;
  background-color: #FFFFFF;
  padding: 5px;
  border-radius: 4px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
  -webkit-transition: all 150ms linear;
  -o-transition: all 150ms linear;
  transition: all 150ms linear;
  opacity: 0;
  visibility: hidden;
  z-index: 10000;
  line-height: 20px;
  width: 100%;
  max-height: 217px;
  overflow: auto;
  top: 35px;
}
.c-select.form-line--icon .c-select__list {
    width: calc(100% - 20px);
    right: 0;
}
.c-select__list li, .c-select__list .c-radio {
  font-size: 14px;
  font-weight: 400;
  border-radius: 3px;
  padding: 0px 10px;
  -webkit-transition: all 150ms linear;
  -o-transition: all 150ms linear;
  transition: all 150ms linear;
}
.c-select__list li:hover, .c-select__list li.is-selected, .c-select__list .c-radio:hover, .c-select__list .c-radio.is-selected {
  background-color: #ffbf00;
  color: #FFFFFF;
  box-shadow: 0 14px 26px -12px rgba(255, 102, 0, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(255, 102, 0, 0.2);
}
.c-select__list li a, .c-select__list .c-radio a {
  display: block;
  color: inherit;
  position: relative;
  padding: 10px 0px;
  -webkit-transition: initial;
  -o-transition: initial;
  transition: initial;
}
.c-select__list .c-radio {
  margin-top: 0;
  margin-bottom: 5px;
}
.c-select__list .c-radio label {
  padding-left: 0;
  display: block;
  color: inherit;
  padding: 10px 0px;
}
.c-select__list .c-radio [class^="c-radio__"] {
  display: none;
}
.c-select__list .c-checkbox {
  margin: 10px;
}
.c-select__list .c-checkbox label:hover span {
  color: #ffbf00;
}
.c-select.is-focused .c-select__list {
  opacity: 1;
  visibility: visible;
  top: 40px;
}
.c-select.is-focused > .c-icons i {
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
  -webkit-transform: rotate(180deg) translate(50%, 50%);
  transform: rotate(180deg) translate(50%, 50%);
}
@media (min-width: 992px) {
    .b-product--hover {
        -webkit-transition: border 0.2s linear;
        -o-transition: border 0.2s linear;
        transition: border 0.2s linear;
        border: 1px solid transparent;
        border-bottom: 2px solid transparent;
        padding: 5px;
    }
    .b-product--hover:hover {
        border: 1px solid #EEEEEE;
        border-bottom: 2px solid #FFD351;
    }
}
@media (min-width: 1200px) {
    [class*="s-header-2"] .s-header__bot .hd-menu__list>li.is-active>a, [class*="s-header-2"] .s-header__bot .hd-menu__list>li:hover>a {
        color: #ffbf00;
    }
    [class*="s-header-2"] .s-header__bot .hd-menu__list>li:hover .sub-menu {
        top: 100%;
        opacity: 1;
        visibility: visible;
    }
}
@media (max-width: 1199px) {
    [class*="s-header-2"] .s-header__bot .hd-logo {
        font-size: 8px;
        margin: 5px 0;
    }
    [class*="s-header-2"] .s-header__bot .hd-menu {
        position: relative;
    }
    [class*="s-header-2"] .s-header__bot .hd-menu__list {
        position: absolute;
        top: 43px;
        right: 0;
        width: 300px;
        padding: 15px;
        background-color: #FDB813;
        -webkit-animation-duration: 1s;
        animation-duration: 1s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
        -webkit-animation-name: fadeOutDown;
        animation-name: fadeOutDown;
        opacity: 0;
        visibility: hidden;
        z-index: -1;
        display: none;
    }
    [class*="s-header-2"] .s-header__bot .hd-menu__list::before {
        height: 0;
        width: 0;
        border-bottom: 10px solid #FDB813;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        position: absolute;
        top: -10px;
        right: 57px;
    }
    [class*="s-header-2"] .s-header__bot .hd-menu__list.is-active {
        opacity: 1;
        visibility: visible;
        z-index: 99999;
        display: block;
        -webkit-animation-name: fadeInUp;
        animation-name: fadeInUp;
    }
    [class*="s-header-2"] .s-header__bot .hd-menu__list>li {
        float: none;
        margin-right: 0;
        position: relative;
    }
    [class*="s-header-2"] .s-header__bot .hd-menu__list>li:first-child {
        display: none;
    }
    [class*="s-header-2"] .s-header__bot .hd-menu__list>li>a {
        color: #FFFFFF;
    }
    [class*="s-header-2"] .s-header__bot .hd-menu__list>li>a h2:before {
        content: "\f1ce";
        display: inline-block;
        font-family: FontAwesome;
        font-size: 0.58em;
        margin: 0 6px;
        -webkit-transform: translateY(-0.25em);
        transform: translateY(-0.25em);
    }
    [class*="s-header-2"] .s-header__bot .hd-menu__list>li.is-active>a, [class*="s-header-2"] .s-header__bot .hd-menu__list>li.is-focus>a {
        color: #00bbff;
    }
    .s-header-2b .s-header__bot .hd-menu__list li:not(:last-child) {
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    }
    .s-header-2b .s-header__bot .hd-menu__list li>.sub-menu {
        width: 100%;
        top: 37px;
    }
    .s-header-2b .s-header__bot .hd-menu__list li>.sub-menu li a {
        padding: 10px 20px;
    }
    .s-header-2b .s-header__bot .hd-menu__list li>.sub-menu li a:before,
    .s-header-2b .s-header__bot .hd-menu__list li>.sub-menu li a:hover:before {
        opacity: 1;
        -webkit-transform: translateX(10px) translateY(3px);
        transform: translateX(10px) translateY(3px);
    }
}
@media (max-width: 479px) {
    .s-dnews__source li.cate, [class*="s-header-2"] .s-header__top ul.hd-contact li:first-child {
        display: none;
    }
    .s-dnews__other {
        margin-left: 0;
    }
    .b-news__content-title, .b-product__content-title {
        font-size: 14px;
    }
    [class*="c-headline-"] {
        font-size: 16px;
    }
}