@charset "UTF-8";
.align-items--center, .align-items--between, .align-items {
    display: -ms-flex;
    display: -webkit-flex;
    display: flex;
    -moz-align-items: center;
    -webkit-align-items: center;
    align-items: center;
}
.align-items--center:after, .align-items--between:after, .align-items:after, .align-items--center:before, .align-items--between:before, .align-items:before {
    content: initial !important;
}
.align-items--center {
    -webkit-justify-content: center;
    justify-content: center;
}
.align-items--between {
    -webkit-justify-content: space-between;
    justify-content: space-between;
}
.container:before, .container-fluid:before, .row:before, .clearfix:before, .ht-page:before, .form-group:before, .form-line--icon:before, .form-border--icon:before, .form-round--icon:before, .container:after, .container-fluid:after, .row:after, .clearfix:after, .ht-page:after, .form-group:after, .form-line--icon:after, .form-border--icon:after, .form-round--icon:after {
    content: " ";
    display: table;
}
.container:after, .container-fluid:after, .row:after, .clearfix:after, .ht-page:after, .form-group:after, .form-line--icon:after, .form-border--icon:after, .form-round--icon:after {
    clear: both;
}
.container {
    margin-right: auto;
    margin-left: auto;
    padding-left: 2.5%;
    padding-right: 2.5%;
}
@media (min-width: 576px) {
    .container {
        padding-left: 1.25%;
        padding-right: 1.25%;
    }
}
@media (min-width: 576px) {
    .container {
        width: 540px;
    }
}
@media (min-width: 768px) {
    .container {
        width: 720px;
    }
}
@media (min-width: 992px) {
    .container {
        width: 960px;
    }
}
@media (min-width: 1200px) {
    .container {
        width: 1140px;
    }
}
.container-fluid {
    margin-right: auto;
    margin-left: auto;
    padding-left: 2.5%;
    padding-right: 2.5%;
}
@media (min-width: 576px) {
    .container-fluid {
        padding-left: 1.25%;
        padding-right: 1.25%;
    }
}
[class*="row-flex"]:after, [class*="row-flex"]:before {
    content: initial !important;
}
.row {
    margin-left: -1.25%;
    margin-right: -1.25%;
}
.col-1, .col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-2, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-3, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-4, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-5, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-6, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-7, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-8, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-9, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-10, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-11, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-12, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12, .col-2-4, .col-xs-2-4, .col-sm-2-4, .col-md-2-4, .col-lg-2-4 {
    position: relative;
    min-height: 1px;
    padding-left: 1.25%;
    padding-right: 1.25%;
}
.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col-2-4 {
    float: left;
}
.col-1 {
    width: 8.3333333333%;
}
.row-flex {
    display: -ms-flex;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
}
.col-2-4 {
    width: 20%;
}
.col-2 {
    width: 16.6666666667%;
}
.col-3 {
    width: 25%;
}
.col-4 {
    width: 33.3333333333%;
}
.col-5 {
    width: 41.6666666667%;
}
.col-6 {
    width: 50%;
}
.col-7 {
    width: 58.3333333333%;
}
.col-8 {
    width: 66.6666666667%;
}
.col-9 {
    width: 75%;
}
.col-10 {
    width: 83.3333333333%;
}
.col-11 {
    width: 91.6666666667%;
}
.col-12 {
    width: 100%;
}
.col-pull-0 {
    right: auto;
}
.col-pull-1 {
    right: 8.3333333333%;
}
.col-pull-2 {
    right: 16.6666666667%;
}
.col-pull-3 {
    right: 25%;
}
.col-pull-4 {
    right: 33.3333333333%;
}
.col-pull-5 {
    right: 41.6666666667%;
}
.col-pull-6 {
    right: 50%;
}
.col-pull-7 {
    right: 58.3333333333%;
}
.col-pull-8 {
    right: 66.6666666667%;
}
.col-pull-9 {
    right: 75%;
}
.col-pull-10 {
    right: 83.3333333333%;
}
.col-pull-11 {
    right: 91.6666666667%;
}
.col-pull-12 {
    right: 100%;
}
.col-push-0 {
    left: auto;
}
.col-push-1 {
    left: 8.3333333333%;
}
.col-push-2 {
    left: 16.6666666667%;
}
.col-push-3 {
    left: 25%;
}
.col-push-4 {
    left: 33.3333333333%;
}
.col-push-5 {
    left: 41.6666666667%;
}
.col-push-6 {
    left: 50%;
}
.col-push-7 {
    left: 58.3333333333%;
}
.col-push-8 {
    left: 66.6666666667%;
}
.col-push-9 {
    left: 75%;
}
.col-push-10 {
    left: 83.3333333333%;
}
.col-push-11 {
    left: 91.6666666667%;
}
.col-push-12 {
    left: 100%;
}
.col-offset-0 {
    margin-left: 0%;
}
.col-offset-1 {
    margin-left: 8.3333333333%;
}
.col-offset-2 {
    margin-left: 16.6666666667%;
}
.col-offset-3 {
    margin-left: 25%;
}
.col-offset-4 {
    margin-left: 33.3333333333%;
}
.col-offset-5 {
    margin-left: 41.6666666667%;
}
.col-offset-6 {
    margin-left: 50%;
}
.col-offset-7 {
    margin-left: 58.3333333333%;
}
.col-offset-8 {
    margin-left: 66.6666666667%;
}
.col-offset-9 {
    margin-left: 75%;
}
.col-offset-10 {
    margin-left: 83.3333333333%;
}
.col-offset-11 {
    margin-left: 91.6666666667%;
}
.col-offset-12 {
    margin-left: 100%;
}
@media (min-width: 576px) {
    .col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12, .col-xs-2-4 {
        float: left;
    }
    .col-xs-1 {
        width: 8.3333333333%;
    }
    .row-flex-xs {
        display: -ms-flex;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-wrap: wrap;
        flex-wrap: wrap;
    }
    .col-xs-2-4 {
        width: 20%;
    }
    .col-xs-2 {
        width: 16.6666666667%;
    }
    .col-xs-3 {
        width: 25%;
    }
    .col-xs-4 {
        width: 33.3333333333%;
    }
    .col-xs-5 {
        width: 41.6666666667%;
    }
    .col-xs-6 {
        width: 50%;
    }
    .col-xs-7 {
        width: 58.3333333333%;
    }
    .col-xs-8 {
        width: 66.6666666667%;
    }
    .col-xs-9 {
        width: 75%;
    }
    .col-xs-10 {
        width: 83.3333333333%;
    }
    .col-xs-11 {
        width: 91.6666666667%;
    }
    .col-xs-12 {
        width: 100%;
    }
    .col-xs-pull-0 {
        right: auto;
    }
    .col-xs-pull-1 {
        right: 8.3333333333%;
    }
    .col-xs-pull-2 {
        right: 16.6666666667%;
    }
    .col-xs-pull-3 {
        right: 25%;
    }
    .col-xs-pull-4 {
        right: 33.3333333333%;
    }
    .col-xs-pull-5 {
        right: 41.6666666667%;
    }
    .col-xs-pull-6 {
        right: 50%;
    }
    .col-xs-pull-7 {
        right: 58.3333333333%;
    }
    .col-xs-pull-8 {
        right: 66.6666666667%;
    }
    .col-xs-pull-9 {
        right: 75%;
    }
    .col-xs-pull-10 {
        right: 83.3333333333%;
    }
    .col-xs-pull-11 {
        right: 91.6666666667%;
    }
    .col-xs-pull-12 {
        right: 100%;
    }
    .col-xs-push-0 {
        left: auto;
    }
    .col-xs-push-1 {
        left: 8.3333333333%;
    }
    .col-xs-push-2 {
        left: 16.6666666667%;
    }
    .col-xs-push-3 {
        left: 25%;
    }
    .col-xs-push-4 {
        left: 33.3333333333%;
    }
    .col-xs-push-5 {
        left: 41.6666666667%;
    }
    .col-xs-push-6 {
        left: 50%;
    }
    .col-xs-push-7 {
        left: 58.3333333333%;
    }
    .col-xs-push-8 {
        left: 66.6666666667%;
    }
    .col-xs-push-9 {
        left: 75%;
    }
    .col-xs-push-10 {
        left: 83.3333333333%;
    }
    .col-xs-push-11 {
        left: 91.6666666667%;
    }
    .col-xs-push-12 {
        left: 100%;
    }
    .col-xs-offset-0 {
        margin-left: 0%;
    }
    .col-xs-offset-1 {
        margin-left: 8.3333333333%;
    }
    .col-xs-offset-2 {
        margin-left: 16.6666666667%;
    }
    .col-xs-offset-3 {
        margin-left: 25%;
    }
    .col-xs-offset-4 {
        margin-left: 33.3333333333%;
    }
    .col-xs-offset-5 {
        margin-left: 41.6666666667%;
    }
    .col-xs-offset-6 {
        margin-left: 50%;
    }
    .col-xs-offset-7 {
        margin-left: 58.3333333333%;
    }
    .col-xs-offset-8 {
        margin-left: 66.6666666667%;
    }
    .col-xs-offset-9 {
        margin-left: 75%;
    }
    .col-xs-offset-10 {
        margin-left: 83.3333333333%;
    }
    .col-xs-offset-11 {
        margin-left: 91.6666666667%;
    }
    .col-xs-offset-12 {
        margin-left: 100%;
    }
}
@media (min-width: 768px) {
    .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2-4 {
        float: left;
    }
    .col-sm-1 {
        width: 8.3333333333%;
    }
    .row-flex-sm {
        display: -ms-flex;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-wrap: wrap;
        flex-wrap: wrap;
    }
    .col-sm-2-4 {
        width: 20%;
    }
    .col-sm-2 {
        width: 16.6666666667%;
    }
    .col-sm-3 {
        width: 25%;
    }
    .col-sm-4 {
        width: 33.3333333333%;
    }
    .col-sm-5 {
        width: 41.6666666667%;
    }
    .col-sm-6 {
        width: 50%;
    }
    .col-sm-7 {
        width: 58.3333333333%;
    }
    .col-sm-8 {
        width: 66.6666666667%;
    }
    .col-sm-9 {
        width: 75%;
    }
    .col-sm-10 {
        width: 83.3333333333%;
    }
    .col-sm-11 {
        width: 91.6666666667%;
    }
    .col-sm-12 {
        width: 100%;
    }
    .col-sm-pull-0 {
        right: auto;
    }
    .col-sm-pull-1 {
        right: 8.3333333333%;
    }
    .col-sm-pull-2 {
        right: 16.6666666667%;
    }
    .col-sm-pull-3 {
        right: 25%;
    }
    .col-sm-pull-4 {
        right: 33.3333333333%;
    }
    .col-sm-pull-5 {
        right: 41.6666666667%;
    }
    .col-sm-pull-6 {
        right: 50%;
    }
    .col-sm-pull-7 {
        right: 58.3333333333%;
    }
    .col-sm-pull-8 {
        right: 66.6666666667%;
    }
    .col-sm-pull-9 {
        right: 75%;
    }
    .col-sm-pull-10 {
        right: 83.3333333333%;
    }
    .col-sm-pull-11 {
        right: 91.6666666667%;
    }
    .col-sm-pull-12 {
        right: 100%;
    }
    .col-sm-push-0 {
        left: auto;
    }
    .col-sm-push-1 {
        left: 8.3333333333%;
    }
    .col-sm-push-2 {
        left: 16.6666666667%;
    }
    .col-sm-push-3 {
        left: 25%;
    }
    .col-sm-push-4 {
        left: 33.3333333333%;
    }
    .col-sm-push-5 {
        left: 41.6666666667%;
    }
    .col-sm-push-6 {
        left: 50%;
    }
    .col-sm-push-7 {
        left: 58.3333333333%;
    }
    .col-sm-push-8 {
        left: 66.6666666667%;
    }
    .col-sm-push-9 {
        left: 75%;
    }
    .col-sm-push-10 {
        left: 83.3333333333%;
    }
    .col-sm-push-11 {
        left: 91.6666666667%;
    }
    .col-sm-push-12 {
        left: 100%;
    }
    .col-sm-offset-0 {
        margin-left: 0%;
    }
    .col-sm-offset-1 {
        margin-left: 8.3333333333%;
    }
    .col-sm-offset-2 {
        margin-left: 16.6666666667%;
    }
    .col-sm-offset-3 {
        margin-left: 25%;
    }
    .col-sm-offset-4 {
        margin-left: 33.3333333333%;
    }
    .col-sm-offset-5 {
        margin-left: 41.6666666667%;
    }
    .col-sm-offset-6 {
        margin-left: 50%;
    }
    .col-sm-offset-7 {
        margin-left: 58.3333333333%;
    }
    .col-sm-offset-8 {
        margin-left: 66.6666666667%;
    }
    .col-sm-offset-9 {
        margin-left: 75%;
    }
    .col-sm-offset-10 {
        margin-left: 83.3333333333%;
    }
    .col-sm-offset-11 {
        margin-left: 91.6666666667%;
    }
    .col-sm-offset-12 {
        margin-left: 100%;
    }
}
@media (min-width: 992px) {
    .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md-2-4 {
        float: left;
    }
    .col-md-1 {
        width: 8.3333333333%;
    }
    .row-flex-md {
        display: -ms-flex;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-wrap: wrap;
        flex-wrap: wrap;
    }
    .col-md-2-4 {
        width: 20%;
    }
    .col-md-2 {
        width: 16.6666666667%;
    }
    .col-md-3 {
        width: 25%;
    }
    .col-md-4 {
        width: 33.3333333333%;
    }
    .col-md-5 {
        width: 41.6666666667%;
    }
    .col-md-6 {
        width: 50%;
    }
    .col-md-7 {
        width: 58.3333333333%;
    }
    .col-md-8 {
        width: 66.6666666667%;
    }
    .col-md-9 {
        width: 75%;
    }
    .col-md-10 {
        width: 83.3333333333%;
    }
    .col-md-11 {
        width: 91.6666666667%;
    }
    .col-md-12 {
        width: 100%;
    }
    .col-md-pull-0 {
        right: auto;
    }
    .col-md-pull-1 {
        right: 8.3333333333%;
    }
    .col-md-pull-2 {
        right: 16.6666666667%;
    }
    .col-md-pull-3 {
        right: 25%;
    }
    .col-md-pull-4 {
        right: 33.3333333333%;
    }
    .col-md-pull-5 {
        right: 41.6666666667%;
    }
    .col-md-pull-6 {
        right: 50%;
    }
    .col-md-pull-7 {
        right: 58.3333333333%;
    }
    .col-md-pull-8 {
        right: 66.6666666667%;
    }
    .col-md-pull-9 {
        right: 75%;
    }
    .col-md-pull-10 {
        right: 83.3333333333%;
    }
    .col-md-pull-11 {
        right: 91.6666666667%;
    }
    .col-md-pull-12 {
        right: 100%;
    }
    .col-md-push-0 {
        left: auto;
    }
    .col-md-push-1 {
        left: 8.3333333333%;
    }
    .col-md-push-2 {
        left: 16.6666666667%;
    }
    .col-md-push-3 {
        left: 25%;
    }
    .col-md-push-4 {
        left: 33.3333333333%;
    }
    .col-md-push-5 {
        left: 41.6666666667%;
    }
    .col-md-push-6 {
        left: 50%;
    }
    .col-md-push-7 {
        left: 58.3333333333%;
    }
    .col-md-push-8 {
        left: 66.6666666667%;
    }
    .col-md-push-9 {
        left: 75%;
    }
    .col-md-push-10 {
        left: 83.3333333333%;
    }
    .col-md-push-11 {
        left: 91.6666666667%;
    }
    .col-md-push-12 {
        left: 100%;
    }
    .col-md-offset-0 {
        margin-left: 0%;
    }
    .col-md-offset-1 {
        margin-left: 8.3333333333%;
    }
    .col-md-offset-2 {
        margin-left: 16.6666666667%;
    }
    .col-md-offset-3 {
        margin-left: 25%;
    }
    .col-md-offset-4 {
        margin-left: 33.3333333333%;
    }
    .col-md-offset-5 {
        margin-left: 41.6666666667%;
    }
    .col-md-offset-6 {
        margin-left: 50%;
    }
    .col-md-offset-7 {
        margin-left: 58.3333333333%;
    }
    .col-md-offset-8 {
        margin-left: 66.6666666667%;
    }
    .col-md-offset-9 {
        margin-left: 75%;
    }
    .col-md-offset-10 {
        margin-left: 83.3333333333%;
    }
    .col-md-offset-11 {
        margin-left: 91.6666666667%;
    }
    .col-md-offset-12 {
        margin-left: 100%;
    }
}
@media (min-width: 1200px) {
    .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2-4 {
        float: left;
    }
    .col-lg-1 {
        width: 8.3333333333%;
    }
    .row-flex-lg {
        display: -ms-flex;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-wrap: wrap;
        flex-wrap: wrap;
    }
    .col-lg-2-4 {
        width: 20%;
    }
    .col-lg-2 {
        width: 16.6666666667%;
    }
    .col-lg-3 {
        width: 25%;
    }
    .col-lg-4 {
        width: 33.3333333333%;
    }
    .col-lg-5 {
        width: 41.6666666667%;
    }
    .col-lg-6 {
        width: 50%;
    }
    .col-lg-7 {
        width: 58.3333333333%;
    }
    .col-lg-8 {
        width: 66.6666666667%;
    }
    .col-lg-9 {
        width: 75%;
    }
    .col-lg-10 {
        width: 83.3333333333%;
    }
    .col-lg-11 {
        width: 91.6666666667%;
    }
    .col-lg-12 {
        width: 100%;
    }
    .col-lg-pull-0 {
        right: auto;
    }
    .col-lg-pull-1 {
        right: 8.3333333333%;
    }
    .col-lg-pull-2 {
        right: 16.6666666667%;
    }
    .col-lg-pull-3 {
        right: 25%;
    }
    .col-lg-pull-4 {
        right: 33.3333333333%;
    }
    .col-lg-pull-5 {
        right: 41.6666666667%;
    }
    .col-lg-pull-6 {
        right: 50%;
    }
    .col-lg-pull-7 {
        right: 58.3333333333%;
    }
    .col-lg-pull-8 {
        right: 66.6666666667%;
    }
    .col-lg-pull-9 {
        right: 75%;
    }
    .col-lg-pull-10 {
        right: 83.3333333333%;
    }
    .col-lg-pull-11 {
        right: 91.6666666667%;
    }
    .col-lg-pull-12 {
        right: 100%;
    }
    .col-lg-push-0 {
        left: auto;
    }
    .col-lg-push-1 {
        left: 8.3333333333%;
    }
    .col-lg-push-2 {
        left: 16.6666666667%;
    }
    .col-lg-push-3 {
        left: 25%;
    }
    .col-lg-push-4 {
        left: 33.3333333333%;
    }
    .col-lg-push-5 {
        left: 41.6666666667%;
    }
    .col-lg-push-6 {
        left: 50%;
    }
    .col-lg-push-7 {
        left: 58.3333333333%;
    }
    .col-lg-push-8 {
        left: 66.6666666667%;
    }
    .col-lg-push-9 {
        left: 75%;
    }
    .col-lg-push-10 {
        left: 83.3333333333%;
    }
    .col-lg-push-11 {
        left: 91.6666666667%;
    }
    .col-lg-push-12 {
        left: 100%;
    }
    .col-lg-offset-0 {
        margin-left: 0%;
    }
    .col-lg-offset-1 {
        margin-left: 8.3333333333%;
    }
    .col-lg-offset-2 {
        margin-left: 16.6666666667%;
    }
    .col-lg-offset-3 {
        margin-left: 25%;
    }
    .col-lg-offset-4 {
        margin-left: 33.3333333333%;
    }
    .col-lg-offset-5 {
        margin-left: 41.6666666667%;
    }
    .col-lg-offset-6 {
        margin-left: 50%;
    }
    .col-lg-offset-7 {
        margin-left: 58.3333333333%;
    }
    .col-lg-offset-8 {
        margin-left: 66.6666666667%;
    }
    .col-lg-offset-9 {
        margin-left: 75%;
    }
    .col-lg-offset-10 {
        margin-left: 83.3333333333%;
    }
    .col-lg-offset-11 {
        margin-left: 91.6666666667%;
    }
    .col-lg-offset-12 {
        margin-left: 100%;
    }
}
 :root {
    font-size: 10px;
}
body {
    font-family: Arial, Helvetica, sans-serif;
    line-height: 1.42857143;
    color: #222222;
    font-size: 14px;
}
a {
    color: #222222;
    text-decoration: none;
}
span.fa {
    position: relative;
    font-family: inherit;
    font-size: inherit;
    padding-left: 1.43em;
    display: inline-block;
}
span.fa:before {
    font-family: FontAwesome;
    font-size: inherit;
    position: absolute;
    line-height: 0;
    left: 0;
    top: 50%;
    color: #999999;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}
span.fa.fa-abs-top:before {
    top: 0;
    line-height: inherit;
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
}
.center-block, .modal-box {
    display: block;
    margin-left: auto;
    margin-right: auto;
    float: none;
}
.pull-right {
    float: right !important;
}
.pull-left {
    float: left !important;
}
.hide {
    display: none !important;
}
.show {
    display: block !important;
}
.invisible {
    visibility: hidden;
}
.hidden {
    display: none !important;
    visibility: hidden !important;
}
.visible-xs, tr.visible-xs, th.visible-xs, td.visible-xs {
    display: none !important;
}
@media (max-width: 767px) {
    .visible-xs {
        display: block !important;
    }
    table.visible-xs {
        display: table;
    }
    tr.visible-xs {
        display: table-row !important;
    }
    th.visible-xs, td.visible-xs {
        display: table-cell !important;
    }
}
.visible-sm, tr.visible-sm, th.visible-sm, td.visible-sm {
    display: none !important;
}
@media (min-width: 768px) and (max-width: 991px) {
    .visible-sm {
        display: block !important;
    }
    table.visible-sm {
        display: table;
    }
    tr.visible-sm {
        display: table-row !important;
    }
    th.visible-sm, td.visible-sm {
        display: table-cell !important;
    }
}
.visible-md, tr.visible-md, th.visible-md, td.visible-md {
    display: none !important;
}
@media (min-width: 992px) and (max-width: 1199px) {
    .visible-md {
        display: block !important;
    }
    table.visible-md {
        display: table;
    }
    tr.visible-md {
        display: table-row !important;
    }
    th.visible-md, td.visible-md {
        display: table-cell !important;
    }
}
.visible-lg, tr.visible-lg, th.visible-lg, td.visible-lg {
    display: none !important;
}
@media (min-width: 1200px) {
    .visible-lg {
        display: block !important;
    }
    table.visible-lg {
        display: table;
    }
    tr.visible-lg {
        display: table-row !important;
    }
    th.visible-lg, td.visible-lg {
        display: table-cell !important;
    }
}
@media (max-width: 767px) {
    .hidden-xs, tr.hidden-xs, th.hidden-xs, td.hidden-xs {
        display: none !important;
    }
}
@media (min-width: 768px) and (max-width: 991px) {
    .hidden-sm, tr.hidden-sm, th.hidden-sm, td.hidden-sm {
        display: none !important;
    }
}
@media (min-width: 992px) and (max-width: 1199px) {
    .hidden-md, tr.hidden-md, th.hidden-md, td.hidden-md {
        display: none !important;
    }
}
@media (min-width: 1200px) {
    .hidden-lg, tr.hidden-lg, th.hidden-lg, td.hidden-lg {
        display: none !important;
    }
}
.text--default {
    color: #222222;
}
.text--primary {
    color: #00bbff;
}
.text--secondary {
    color: #ffbf00;
}
.text--success {
    color: #55b559;
}
.text--error {
    color: #f55145;
}
.text--warning {
    color: #ff9e0f;
}
.text--info {
    color: #00cae3;
}
.text--icon {
    color: #999999;
}
.text--muted {
    color: #909090;
}
.text--white {
    color: #FFFFFF;
}
.text-center {
    text-align: center !important;
}
.text-right {
    text-align: right !important;
}
.text-left {
    text-align: left !important;
}
.text-bold {
    font-weight: bold;
}
.text-hide {
    font: 0/0 a;
    color: transparent;
    text-shadow: none;
    background-color: transparent;
    border: 0;
}
.text-uppercase {
    text-transform: uppercase;
}
.text-uppercase-none {
    text-transform: none;
}
.text--muted {
    color: #919191;
}
.text--hypertext {
    color: blue;
}
.text--hypertext:hover {
    color: #ffbf00;
}
.bg--default {
    background-color: #222222;
}
.bg--primary {
    background-color: #00bbff;
}
.bg--secondary {
    background-color: #ffbf00;
}
.bg--success {
    background-color: #55b559;
}
.bg--error {
    background-color: #f55145;
}
.bg--warning {
    background-color: #ff9e0f;
}
.bg--info {
    background-color: #00cae3;
}
.bg--icon {
    background-color: #999999;
}
.bg--muted {
    background-color: #909090;
}
.bg--white {
    background-color: #FFFFFF;
}
.border--default {
    border-color: #222222;
}
.border--primary {
    border-color: #00bbff;
}
.border--secondary {
    border-color: #ffbf00;
}
.border--success {
    border-color: #55b559;
}
.border--error {
    border-color: #f55145;
}
.border--warning {
    border-color: #ff9e0f;
}
.border--info {
    border-color: #00cae3;
}
.border--icon {
    border-color: #999999;
}
.border--muted {
    border-color: #909090;
}
.border--white {
    border-color: #FFFFFF;
}
i+span, span+i, i+a, a+i {
    margin-left: 0.3em;
}
.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.68);
    opacity: 0;
    -webkit-transition: all 150ms linear;
    -o-transition: all 150ms linear;
    transition: all 150ms linear;
    z-index: 2;
    text-align: center;
}
.overlay-transparent {
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
}
.overlay .c-btn {
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    opacity: 0.5;
}
.overlay .c-btn:hover {
    opacity: 1;
}
.display-inline {
    display: inline-block;
}
.display-block {
    display: block;
}
.ht-page {
    margin-top: 15px;
}
@media screen and (min-width: 992px) {
    .ht-page__sidebar {
        width: 230px;
        float: left;
        min-height: 1px;
    }
    .ht-page__sidebar+.ht-page__main {
        margin-left: 20px;
    }
    .ht-page__main {
        width: calc(100% - 250px);
        float: left;
    }
    .ht-page__main+.ht-page__sidebar {
        margin-left: 20px;
    }
}
@media screen and (min-width: 1200px) {
    .ht-page__sidebar {
        width: 250px;
        float: left;
        min-height: 1px;
    }
    .ht-page__sidebar+.ht-page__main {
        margin-left: 30px;
    }
    .ht-page__main {
        width: calc(100% - 280px);
        float: left;
    }
    .ht-page__main+.ht-page__sidebar {
        margin-left: 30px;
    }
}
.ht-scrollbar {
    display: none;
    position: absolute;
    width: 17px;
    height: 203px;
    z-index: 1111;
    top: 31px;
    border: 5px solid #fff;
}
.ht-format-detail a {
    color: blue;
    background: none;
}
.ht-format-detail a:hover {
    color: #ffbf00;
}
.ht-format-detail ul, .ht-format-detail ol {
    list-style: initial;
    padding-left: 15px;
}
.ht-format-detail img {
    height: auto !important;
}
.ht-format-detail table {
    max-width: 100%;
}
.ht-format-detail table p {
    margin-bottom: 0;
}
.ht-format-detail table td, .ht-format-detail table th {
    padding: 0 5px;
}
.ht-loading-gif {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999999;
    width: 100%;
    height: 100%;
    background-color: rgba(51, 51, 51, 0.31);
    display: -ms-flex;
    display: -webkit-flex;
    display: flex;
    -moz-align-items: center;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    justify-content: center;
}
.ht-loading-gif:after, .ht-loading-gif:before {
    content: initial !important;
}
.ht-arrow {
    font-size: 14px;
    position: absolute;
    top: 50%;
    width: 2.5em;
    height: 2.5em;
    z-index: 1;
    cursor: pointer;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}
.ht-arrow:before, .ht-arrow:after {
    content: '';
    position: absolute;
    border-color: #00bbff;
    border-style: solid;
    border-right-color: transparent !important;
    border-bottom-color: transparent !important;
}
.ht-arrow:before {
    top: 0;
    left: 0;
    border-width: 2px;
    width: 2.15em;
    height: 2.15em;
}
.ht-arrow:after {
    left: 50%;
    top: 50%;
    width: 1.22em;
    height: 1.22em;
    border-width: 1px;
    margin: -0.71em 0 0 -0.71em;
    -webkit-animation-duration: 2s;
    animation-duration: 2s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn;
}
.ht-arrow:hover:before, .ht-arrow:hover:after {
    border-color: #ffbf00;
}
.ht-arrow--left {
    left: 10px;
}
.ht-arrow--left:before, .ht-arrow--left:after {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
}
.ht-arrow--right {
    right: 10px;
}
.ht-arrow--right:before, .ht-arrow--right:after {
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
}
.ht-lineheight0 {
    line-height: 0;
}
.ht-hover a:hover {
    color: #ffbf00;
}
h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    font-weight: 500;
    margin: 0;
    padding: 0;
}
h1, .h1 {
    font-size: 22px;
}
h2, .h2 {
    font-size: 18px;
}
h3, .h3 {
    font-size: 16px;
}
h4, .h4 {
    font-size: 14px;
}
h5, .h5 {
    font-size: 13px;
}
h6, .h6 {
    font-size: 12px;
}
small, .small {
    font-size: 80%;
}
.h1 .small, .h1 small, .h2 .small, .h2 small, .h3 .small, .h3 small, .h4 .small, .h4 small, .h5 .small, .h5 small, .h6 .small, .h6 small, h1 .small, h1 small, h2 .small, h2 small, h3 .small, h3 small, h4 .small, h4 small, h5 .small, h5 small, h6 .small, h6 small {
    font-weight: 400;
    line-height: 1;
    color: #777;
}

/* Style chung */

[class*="c-headline-"] {
    margin-bottom: 20px;
}
[class*="c-headline-"] .c-headline__name {
    text-transform: uppercase;
    font-size: inherit;
}
[class*="c-headline-"] .c-headline__name a {
    -webkit-transition: 0.3s all ease-in-out;
    -o-transition: 0.3s all ease-in-out;
    transition: 0.3s all ease-in-out;
}
[class*="c-headline-"] .c-headline__name a:hover {
    color: #ffbf00;
}
[class*="c-headline-"] i {
    color: #00bbff;
}
@media screen and (max-width: 479px) {
    [class*="c-headline-"] {
        font-size: 15px;
    }
}

/* c-headline-1 */

.c-headline-1 {
    border-bottom: 1px solid #ccc;
    margin-bottom: 20px;
}
.c-headline-1 .c-headline__name {
    display: inline-block;
    position: relative;
    top: 10px;
    background-color: #FFFFFF;
    padding: 0 10px;
    margin: 0 20px;
}

/* c-headline-2 */

.c-headline-2 {
    text-align: center;
    text-transform: uppercase;
}
.c-headline-2 .c-headline__divider {
    text-align: center;
    font-size: inherit;
}
.c-headline-2 .c-headline__divider::before, .c-headline-2 .c-headline__divider::after {
    content: '';
    display: inline-block;
    width: 100px;
    height: 1px;
    background-color: rgba(255, 0, 0, 0);
    background-image: -webkit-linear-gradient(left, rgba(255, 0, 0, 0), #CCCCCC 50%, rgba(255, 0, 0, 0));
    background-image: linear-gradient(to right, rgba(255, 0, 0, 0), #CCCCCC 50%, rgba(255, 0, 0, 0));
}
@media (max-width: 479px) {
    .c-headline-2 .c-headline__divider:first-child::before, .c-headline-2 .c-headline__divider:first-child::after {
        display: block;
        margin: 2 auto;
    }
}
.c-headline-2 .c-headline__divider i {
    position: relative;
    top: 5px;
}

/* c-headline-3 */

.c-headline-3 {
    position: relative;
}
.c-headline-3:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    left: 0;
    bottom: -0.05em;
    background-color: rgba(255, 0, 0, 0);
    background-image: -webkit-linear-gradient(right, rgba(255, 0, 0, 0), #CCCCCC 300%);
    background-image: linear-gradient(to left, rgba(255, 0, 0, 0), #CCCCCC 300%);
}
.c-headline-3 i {
    position: relative;
}
.c-headline-3 i:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: #ffbf00;
    left: 0;
    bottom: -0.27em;
    z-index: 1;
}
button, .c-btn, [class*="c-btn-"] {
    font-size: 14px;
    padding: 0.72em 1.43em 0.72em 1.43em;
    cursor: pointer;
    border: none;
    border-radius: 0.3em;
    text-transform: uppercase;
    line-height: 1;
    background-color: #999999;
    color: #FFFFFF;
    box-shadow: 0 2px 2px 0 rgba(34, 34, 34, 0.14), 0 3px 1px -2px rgba(34, 34, 34, 0.2), 0 1px 5px 0 rgba(34, 34, 34, 0.12);
    -webkit-transition: all 0.2s;
    -o-transition: all 0.2s;
    transition: all 0.2s;
}
button:active, .c-btn:active, [class*="c-btn-"]:active, button:hover, .c-btn:hover, [class*="c-btn-"]:hover, button:focus, .c-btn:focus, [class*="c-btn-"]:focus {
    box-shadow: 0 14px 26px -12px rgba(34, 34, 34, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(34, 34, 34, 0.2);
    outline: none;
}
[class*="c-btn-border"] {
    box-shadow: none;
    background-color: transparent;
    border: 1px solid #CCCCCC;
    color: #222222;
}
.c-btn--default {
    background-color: #222222;
}
.c-btn--primary {
    background-color: #00bbff;
}
.c-btn--secondary {
    background-color: #ffbf00;
}
.c-btn--success {
    background-color: #55b559;
}
.c-btn--error {
    background-color: #f55145;
}
.c-btn--warning {
    background-color: #ff9e0f;
}
.c-btn--info {
    background-color: #00cae3;
}
.c-btn--icon {
    background-color: #999999;
}
.c-btn--muted {
    background-color: #909090;
}
.c-btn--white {
    background-color: #FFFFFF;
}
.c-btn--default {
    box-shadow: 0 2px 2px 0 rgba(34, 34, 34, 0.14), 0 3px 1px -2px rgba(34, 34, 34, 0.2), 0 1px 5px 0 rgba(34, 34, 34, 0.12);
}
.c-btn--default:focus, .c-btn--default:active, .c-btn--default:hover {
    box-shadow: 0 14px 26px -12px rgba(34, 34, 34, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(34, 34, 34, 0.2);
}
.c-btn--primary {
    box-shadow: 0 2px 2px 0 rgba(25, 118, 210, 0.14), 0 3px 1px -2px rgba(25, 118, 210, 0.2), 0 1px 5px 0 rgba(25, 118, 210, 0.12);
}
.c-btn--primary:focus, .c-btn--primary:active, .c-btn--primary:hover {
    box-shadow: 0 14px 26px -12px rgba(25, 118, 210, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(25, 118, 210, 0.2);
}
.c-btn--secondary {
    box-shadow: 0 2px 2px 0 rgba(255, 102, 0, 0.14), 0 3px 1px -2px rgba(255, 102, 0, 0.2), 0 1px 5px 0 rgba(255, 102, 0, 0.12);
}
.c-btn--secondary:focus, .c-btn--secondary:active, .c-btn--secondary:hover {
    box-shadow: 0 14px 26px -12px rgba(255, 102, 0, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(255, 102, 0, 0.2);
}
.c-btn--success {
    box-shadow: 0 2px 2px 0 rgba(85, 181, 89, 0.14), 0 3px 1px -2px rgba(85, 181, 89, 0.2), 0 1px 5px 0 rgba(85, 181, 89, 0.12);
}
.c-btn--success:focus, .c-btn--success:active, .c-btn--success:hover {
    box-shadow: 0 14px 26px -12px rgba(85, 181, 89, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(85, 181, 89, 0.2);
}
.c-btn--error {
    box-shadow: 0 2px 2px 0 rgba(245, 81, 69, 0.14), 0 3px 1px -2px rgba(245, 81, 69, 0.2), 0 1px 5px 0 rgba(245, 81, 69, 0.12);
}
.c-btn--error:focus, .c-btn--error:active, .c-btn--error:hover {
    box-shadow: 0 14px 26px -12px rgba(245, 81, 69, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(245, 81, 69, 0.2);
}
.c-btn--warning {
    box-shadow: 0 2px 2px 0 rgba(255, 158, 15, 0.14), 0 3px 1px -2px rgba(255, 158, 15, 0.2), 0 1px 5px 0 rgba(255, 158, 15, 0.12);
}
.c-btn--warning:focus, .c-btn--warning:active, .c-btn--warning:hover {
    box-shadow: 0 14px 26px -12px rgba(255, 158, 15, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(255, 158, 15, 0.2);
}
.c-btn--info {
    box-shadow: 0 2px 2px 0 rgba(0, 202, 227, 0.14), 0 3px 1px -2px rgba(0, 202, 227, 0.2), 0 1px 5px 0 rgba(0, 202, 227, 0.12);
}
.c-btn--info:focus, .c-btn--info:active, .c-btn--info:hover {
    box-shadow: 0 14px 26px -12px rgba(0, 202, 227, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 202, 227, 0.2);
}
.c-btn--icon {
    box-shadow: 0 2px 2px 0 rgba(153, 153, 153, 0.14), 0 3px 1px -2px rgba(153, 153, 153, 0.2), 0 1px 5px 0 rgba(153, 153, 153, 0.12);
}
.c-btn--icon:focus, .c-btn--icon:active, .c-btn--icon:hover {
    box-shadow: 0 14px 26px -12px rgba(153, 153, 153, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(153, 153, 153, 0.2);
}
.c-btn--muted {
    box-shadow: 0 2px 2px 0 rgba(144, 144, 144, 0.14), 0 3px 1px -2px rgba(144, 144, 144, 0.2), 0 1px 5px 0 rgba(144, 144, 144, 0.12);
}
.c-btn--muted:focus, .c-btn--muted:active, .c-btn--muted:hover {
    box-shadow: 0 14px 26px -12px rgba(144, 144, 144, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(144, 144, 144, 0.2);
}
.c-btn--white {
    box-shadow: 0 2px 2px 0 rgba(255, 255, 255, 0.14), 0 3px 1px -2px rgba(255, 255, 255, 0.2), 0 1px 5px 0 rgba(255, 255, 255, 0.12);
}
.c-btn--white:focus, .c-btn--white:active, .c-btn--white:hover {
    box-shadow: 0 14px 26px -12px rgba(255, 255, 255, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(255, 255, 255, 0.2);
}
.c-btn-border--default {
    border-color: #222222;
}
.c-btn-border--primary {
    border-color: #00bbff;
}
.c-btn-border--secondary {
    border-color: #ffbf00;
}
.c-btn-border--success {
    border-color: #55b559;
}
.c-btn-border--error {
    border-color: #f55145;
}
.c-btn-border--warning {
    border-color: #ff9e0f;
}
.c-btn-border--info {
    border-color: #00cae3;
}
.c-btn-border--icon {
    border-color: #999999;
}
.c-btn-border--muted {
    border-color: #909090;
}
.c-btn-border--white {
    border-color: #FFFFFF;
}
.c-btn-border--default {
    color: #222222;
}
.c-btn-border--primary {
    color: #00bbff;
}
.c-btn-border--secondary {
    color: #ffbf00;
}
.c-btn-border--success {
    color: #55b559;
}
.c-btn-border--error {
    color: #f55145;
}
.c-btn-border--warning {
    color: #ff9e0f;
}
.c-btn-border--info {
    color: #00cae3;
}
.c-btn-border--icon {
    color: #999999;
}
.c-btn-border--muted {
    color: #909090;
}
.c-btn-border--white {
    color: #FFFFFF;
}
.c-btn-border--default {
    box-shadow: 0 2px 2px 0 rgba(34, 34, 34, 0.14), 0 3px 1px -2px rgba(34, 34, 34, 0.2), 0 1px 5px 0 rgba(34, 34, 34, 0.12);
}
.c-btn-border--default:focus, .c-btn-border--default:active, .c-btn-border--default:hover {
    box-shadow: 0 14px 26px -12px rgba(34, 34, 34, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(34, 34, 34, 0.2);
}
.c-btn-border--primary {
    box-shadow: 0 2px 2px 0 rgba(25, 118, 210, 0.14), 0 3px 1px -2px rgba(25, 118, 210, 0.2), 0 1px 5px 0 rgba(25, 118, 210, 0.12);
}
.c-btn-border--primary:focus, .c-btn-border--primary:active, .c-btn-border--primary:hover {
    box-shadow: 0 14px 26px -12px rgba(25, 118, 210, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(25, 118, 210, 0.2);
}
.c-btn-border--secondary {
    box-shadow: 0 2px 2px 0 rgba(255, 102, 0, 0.14), 0 3px 1px -2px rgba(255, 102, 0, 0.2), 0 1px 5px 0 rgba(255, 102, 0, 0.12);
}
.c-btn-border--secondary:focus, .c-btn-border--secondary:active, .c-btn-border--secondary:hover {
    box-shadow: 0 14px 26px -12px rgba(255, 102, 0, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(255, 102, 0, 0.2);
}
.c-btn-border--success {
    box-shadow: 0 2px 2px 0 rgba(85, 181, 89, 0.14), 0 3px 1px -2px rgba(85, 181, 89, 0.2), 0 1px 5px 0 rgba(85, 181, 89, 0.12);
}
.c-btn-border--success:focus, .c-btn-border--success:active, .c-btn-border--success:hover {
    box-shadow: 0 14px 26px -12px rgba(85, 181, 89, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(85, 181, 89, 0.2);
}
.c-btn-border--error {
    box-shadow: 0 2px 2px 0 rgba(245, 81, 69, 0.14), 0 3px 1px -2px rgba(245, 81, 69, 0.2), 0 1px 5px 0 rgba(245, 81, 69, 0.12);
}
.c-btn-border--error:focus, .c-btn-border--error:active, .c-btn-border--error:hover {
    box-shadow: 0 14px 26px -12px rgba(245, 81, 69, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(245, 81, 69, 0.2);
}
.c-btn-border--warning {
    box-shadow: 0 2px 2px 0 rgba(255, 158, 15, 0.14), 0 3px 1px -2px rgba(255, 158, 15, 0.2), 0 1px 5px 0 rgba(255, 158, 15, 0.12);
}
.c-btn-border--warning:focus, .c-btn-border--warning:active, .c-btn-border--warning:hover {
    box-shadow: 0 14px 26px -12px rgba(255, 158, 15, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(255, 158, 15, 0.2);
}
.c-btn-border--info {
    box-shadow: 0 2px 2px 0 rgba(0, 202, 227, 0.14), 0 3px 1px -2px rgba(0, 202, 227, 0.2), 0 1px 5px 0 rgba(0, 202, 227, 0.12);
}
.c-btn-border--info:focus, .c-btn-border--info:active, .c-btn-border--info:hover {
    box-shadow: 0 14px 26px -12px rgba(0, 202, 227, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 202, 227, 0.2);
}
.c-btn-border--icon {
    box-shadow: 0 2px 2px 0 rgba(153, 153, 153, 0.14), 0 3px 1px -2px rgba(153, 153, 153, 0.2), 0 1px 5px 0 rgba(153, 153, 153, 0.12);
}
.c-btn-border--icon:focus, .c-btn-border--icon:active, .c-btn-border--icon:hover {
    box-shadow: 0 14px 26px -12px rgba(153, 153, 153, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(153, 153, 153, 0.2);
}
.c-btn-border--muted {
    box-shadow: 0 2px 2px 0 rgba(144, 144, 144, 0.14), 0 3px 1px -2px rgba(144, 144, 144, 0.2), 0 1px 5px 0 rgba(144, 144, 144, 0.12);
}
.c-btn-border--muted:focus, .c-btn-border--muted:active, .c-btn-border--muted:hover {
    box-shadow: 0 14px 26px -12px rgba(144, 144, 144, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(144, 144, 144, 0.2);
}
.c-btn-border--white {
    box-shadow: 0 2px 2px 0 rgba(255, 255, 255, 0.14), 0 3px 1px -2px rgba(255, 255, 255, 0.2), 0 1px 5px 0 rgba(255, 255, 255, 0.12);
}
.c-btn-border--white:focus, .c-btn-border--white:active, .c-btn-border--white:hover {
    box-shadow: 0 14px 26px -12px rgba(255, 255, 255, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(255, 255, 255, 0.2);
}
.c-btn--circle {
    padding: 0;
    width: 2.43em;
    height: 2.43em;
    border-radius: 50%;
}
.c-btn--square {
    padding: 0;
    width: 2.43em;
    height: 2.43em;
}
.c-btn--round {
    border-radius: 3em;
}
.c-btn--sm {
    font-size: 1.2rem;
}
.c-btn--lg {
    font-size: 1.6rem;
}

/* ─Éß╗ïnh dß║ính chung cho tß║Ñt cß║ú class ─æ╞░ß╗úc ─æß╗ïnh ngh─⌐a trong biß║┐n .form__input, Xem trong file _variabel.scss*/


/* Style chung cho input */

.form-group {
    position: relative;
    margin-bottom: 15px;
}
.form-line .form__input, .form-border .form__input, .form-round .form__input, .form-checkbox .form__input {
    font-size: 14px;
    width: 100%;
    height: 36px;
    position: relative;
    z-index: 1;
}
.form-line .form__input::-webkit-input-placeholder, .form-border .form__input::-webkit-input-placeholder, .form-round .form__input::-webkit-input-placeholder, .form-checkbox .form__input::-webkit-input-placeholder {
    color: inherit;
}
.form-line .form__input::-moz-placeholder, .form-border .form__input::-moz-placeholder, .form-round .form__input::-moz-placeholder, .form-checkbox .form__input::-moz-placeholder {
    color: inherit;
}
.form-line .form__input:-moz-placeholder, .form-border .form__input:-moz-placeholder, .form-round .form__input:-moz-placeholder, .form-checkbox .form__input:-moz-placeholder {
    color: inherit;
}
.form-line .form__input:-ms-input-placeholder, .form-border .form__input:-ms-input-placeholder, .form-round .form__input:-ms-input-placeholder, .form-checkbox .form__input:-ms-input-placeholder {
    color: inherit;
}
.form-line .form__error, .form-border .form__error, .form-round .form__error, .form-checkbox .form__error {
    width: 100%;
    color: #f55145;
    text-align: right;
    position: absolute;
    top: 50%;
    right: 4px;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}
.form-line .form__error i, .form-border .form__error i, .form-round .form__error i, .form-checkbox .form__error i {
    font-size: 15px;
    cursor: pointer;
}
.form-line .form__error .error-text, .form-border .form__error .error-text, .form-round .form__error .error-text, .form-checkbox .form__error .error-text {
    position: absolute;
    top: 27px;
    right: 0;
    font-size: 12px;
    font-style: italic;
}
.form-line textarea.form__input, .form-border textarea.form__input, .form-round textarea.form__input, .form-checkbox textarea.form__input {
    height: auto;
}
.form-line input[type=file], .form-border input[type=file], .form-round input[type=file], .form-checkbox input[type=file] {
    display: block;
}

/* form-line */

.form-line {
    /* Form c├│ icon */
}
.form-line .form__input {
    padding: 7px 0px;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#00bbff), to(#00bbff)), -webkit-gradient(linear, left top, left bottom, from(#CCCCCC), to(#CCCCCC));
    background-image: linear-gradient(#00bbff, #00bbff), linear-gradient(#CCCCCC, #CCCCCC);
    background-size: 0 2px, 100% 1px;
    background-repeat: no-repeat;
    background-position: center bottom, center calc(100% - 1px);
    background-color: transparent;
    float: none;
    border: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-transition: background 0s ease-out;
    -o-transition: background 0s ease-out;
    transition: background 0s ease-out;
}
.form-line .form__input:focus {
    outline: none;
    background-size: 100% 2px, 100% 1px;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
}
.form-line--label .form__label {
    font-size: 1.4rem;
    color: #333333;
    pointer-events: none;
    position: absolute;
    top: 8px;
    left: 0;
    -webkit-transition: 0.3s ease all;
    -o-transition: 0.3s ease all;
    transition: 0.3s ease all;
}
.form-line--label:not(.is-empty) .form__label, .form-line--label.is-focused .form__label {
    font-size: 1.1rem;
    position: absolute;
    top: -6px;
    left: 0;
    color: #00bbff;
}
.form-line--icon .form__icon {
    font-size: 14px;
    float: left;
    width: 1.25em;
    height: 1.82em;
    text-align: center;
    overflow: hidden;
    vertical-align: top;
    position: relative;
    top: 10px;
    line-height: 0;
}
.form-line--icon>.form__input {
    width: calc(100% - 22px) !important;
    float: right;
    position: relative;
}
.form-line--icon.form-checkbox {
    margin-left: 1.72em;
}
.form-line.is-focused .form__input {
    background-size: 100% 2px, 100% 1px;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
}
.form-line.is-error .form__input:focus {
    background-image: -webkit-gradient(linear, left top, left bottom, from(#f55145), to(#f55145)), -webkit-gradient(linear, left top, left bottom, from(#CCCCCC), to(#CCCCCC));
    background-image: linear-gradient(#f55145, #f55145), linear-gradient(#CCCCCC, #CCCCCC);
}
.form-line.is-error .form__label {
    color: #f55145 !important;
}

/* form-border form-round */

.form-border .form__input, .form-round .form__input {
    background-color: transparent;
    padding: 7px 15px;
    border: 1px solid #CCCCCC;
    -webkit-transition: color 0.3s ease-in-out, border-color 0.3s ease-in-out, background-color 0.3s ease-in-out;
    -o-transition: color 0.3s ease-in-out, border-color 0.3s ease-in-out, background-color 0.3s ease-in-out;
    transition: color 0.3s ease-in-out, border-color 0.3s ease-in-out, background-color 0.3s ease-in-out;
}
.form-border .form__input::-webkit-input-placeholder, .form-round .form__input::-webkit-input-placeholder {
    color: inherit;
}
.form-border .form__input::-moz-placeholder, .form-round .form__input::-moz-placeholder {
    color: inherit;
}
.form-border .form__input:-moz-placeholder, .form-round .form__input:-moz-placeholder {
    color: inherit;
}
.form-border .form__input:-ms-input-placeholder, .form-round .form__input:-ms-input-placeholder {
    color: inherit;
}
.form-border .form__input:focus, .form-round .form__input:focus {
    outline: none;
    border: 1px solid #00bbff;
}
.form-border--icon, .form-round--icon {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
.form-border--icon .form__input, .form-round--icon .form__input {
    position: relative;
    z-index: 2;
    -ms-flex: 1 1 auto;
    -webkit-box-flex: 1;
    flex: 1 1 auto;
    width: 1%;
}
.form-border--icon .form__input:first-child, .form-round--icon .form__input:first-child {
    border-right: none !important;
    border-right: none !important;
}
.form-border--icon .form__input:nth-child(2), .form-round--icon .form__input:nth-child(2) {
    border-left: none !important;
}
.form-border--icon .form__icon, .form-round--icon .form__icon {
    border: 1px solid #CCCCCC;
    -webkit-transition: color 0.3s ease-in-out, border-color 0.3s ease-in-out, background-color 0.3s ease-in-out;
    -o-transition: color 0.3s ease-in-out, border-color 0.3s ease-in-out, background-color 0.3s ease-in-out;
    transition: color 0.3s ease-in-out, border-color 0.3s ease-in-out, background-color 0.3s ease-in-out;
}
.form-border--icon .form__icon:first-child, .form-round--icon .form__icon:first-child {
    border-right: 0 !important;
    padding: 7px 0 7px 15px;
}
.form-border--icon .form__icon:nth-child(2), .form-round--icon .form__icon:nth-child(2) {
    border-left: 0 !important;
    padding: 7px 15px 7px 0;
}
.form-border--icon .form__icon:not(.text-top), .form-round--icon .form__icon:not(.text-top) {
    display: -ms-flex;
    display: -webkit-flex;
    display: flex;
    -moz-align-items: center;
    -webkit-align-items: center;
    align-items: center;
}
.form-border--icon .form__icon:not(.text-top):after, .form-border--icon .form__icon:not(.text-top):before, .form-round--icon .form__icon:not(.text-top):after, .form-round--icon .form__icon:not(.text-top):before {
    content: initial !important;
}
.form-border.is-focused .form__icon, .form-border.is-focused .form__input, .form-round.is-focused .form__icon, .form-round.is-focused .form__input {
    border: 1px solid #00bbff;
}
.form-border.is-error, .form-round.is-error {
    margin-bottom: 25px;
}
.form-border.is-error .form__input, .form-border.is-error .form__icon, .form-round.is-error .form__input, .form-round.is-error .form__icon {
    border: 1px solid #f55145;
}
.form-border.is-error .form__icon+.form__error, .form-round.is-error .form__icon+.form__error {
    text-align: left;
    right: -2px;
}
.form-border.is-error .form__icon+.form__error .error-text, .form-round.is-error .form__icon+.form__error .error-text {
    right: auto;
    left: 0;
    margin: 0;
}

/* form-round */

.form-round .form__input {
    border-radius: 30px;
}
.form-round--icon .form__input:first-child {
    border-radius: 30px 0 0 30px;
}
.form-round--icon .form__input:nth-child(2) {
    border-radius: 0 30px 30px 0;
}
.form-round--icon .form__icon:first-child {
    border-radius: 30px 0 0 30px;
}
.form-round--icon .form__icon:nth-child(2) {
    border-radius: 0 30px 30px 0;
}
.c-checkbox, .c-radio {
    position: relative;
    display: block;
    margin-top: 0.72em;
    margin-bottom: 0.72em;
    line-height: 1;
    z-index: 2;
}
.c-checkbox label {
    cursor: pointer;
    padding-left: 0;
    color: #666666;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
}
.c-checkbox input[type=checkbox] {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    margin: 0;
    z-index: -1;
    width: 0;
    height: 0;
    overflow: hidden;
    pointer-events: none;
}
.c-checkbox__tick {
    padding-right: 0.5em;
}
.c-checkbox__tick::before {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    content: "";
    background-color: rgba(0, 0, 0, 0.84);
    width: 1.08em;
    height: 1.08em;
    border-radius: 100%;
    z-index: 1;
    opacity: 0;
    margin: 0;
    -webkit-transform: scale3d(3, 3, 1);
    -moz-transform: scale3d(3, 3, 1);
    -o-transform: scale3d(3, 3, 1);
    -ms-transform: scale3d(3, 3, 1);
    transform: scale3d(3, 3, 1);
}
.c-checkbox__tick.disable-bg::before {
    display: none;
}
.c-checkbox__tick .check {
    position: relative;
    display: inline-block;
    width: 1.08em;
    height: 1.08em;
    border: 1px solid #666666;
    overflow: hidden;
    z-index: 1;
    border-radius: 3px;
}
.c-checkbox__tick .check::before {
    position: absolute;
    content: "";
    transform: rotate(45deg);
    display: block;
    margin-top: -0.35em;
    margin-left: 0.36em;
    width: 0;
    height: 0;
    box-shadow: 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0 inset;
    -webkit-animation: checkbox-off 0.3s forwards;
    -o-animation: checkbox-off 0.3s forwards;
    animation: checkbox-off 0.3s forwards;
}
.c-checkbox input[type=checkbox]:focus+.c-checkbox__tick .check::after {
    opacity: 0.2;
}
.c-checkbox input[type=checkbox]:checked+.c-checkbox__tick .check {
    background: #00bbff;
}
.c-checkbox input[type=checkbox]:checked+.c-checkbox__tick .check::before {
    color: #FFFFFF;
    box-shadow: 0 0 0 0.72em, 0.72em -0.71em 0 0.72em, 2.29em 0 0 1.43em, 0px 2.29em 0 1.43em, -0.35em 0.36em 0 0.72em, 1.43em -0.85em 0 0.79em;
    -webkit-animation: checkbox-on 0.3s forwards;
    -o-animation: checkbox-on 0.3s forwards;
    animation: checkbox-on 0.3s forwards;
}
.c-checkbox input[type=checkbox]:checked+.c-checkbox__tick:before {
    -webkit-animation: rippleOn 500ms;
    -o-animation: rippleOn 500ms;
    animation: rippleOn 500ms;
}
.c-checkbox input[type=checkbox]:checked+.c-checkbox__tick .check:after {
    -webkit-animation: rippleOn 500ms forwards;
    -o-animation: rippleOn 500ms forwards;
    animation: rippleOn 500ms forwards;
}
.c-checkbox input[type=checkbox]:not(:checked)+.c-checkbox__tick:before {
    -webkit-animation: rippleOff 500ms;
    -o-animation: rippleOff 500ms;
    animation: rippleOff 500ms;
}
.c-checkbox input[type=checkbox]:not(:checked)+.c-checkbox__tick .check:after {
    -webkit-animation: rippleOff 500ms;
    -o-animation: rippleOff 500ms;
    animation: rippleOff 500ms;
}
fieldset[disabled] .c-checkbox, fieldset[disabled] .c-checkbox input[type=checkbox], .c-checkbox input[type=checkbox][disabled]~.c-checkbox__tick .check, .c-checkbox input[type=checkbox][disabled]+.circle {
    opacity: 0.5;
}
.c-checkbox input[type=checkbox][disabled]~.c-checkbox__tick .check {
    border-color: #000000;
    opacity: .26;
}
.c-checkbox input[type=checkbox][disabled]+.c-checkbox__tick .check:after {
    background-color: #00bbff;
    transform: rotate(-45deg);
}
@-webkit-keyframes checkbox-on {
    0% {
        box-shadow: 0 0 0 0.72em, 0.72em -0.71em 0 0.72em, 2.29em 0 0 1.43em, 0px 2.29em 0 1.43em, -0.35em 0.36em 0 0.72em, 1.08em 0.15em 0 0.79em;
    }
    50% {
        box-shadow: 0 0 0 0.72em, 0.72em -0.71em 0 0.72em, 2.29em 0 0 1.43em, 0px 2.29em 0 1.43em, -0.35em 0.36em 0 0.72em, 1.43em 0.15em 0 0.79em;
    }
    100% {
        box-shadow: 0 0 0 0.72em, 0.72em -0.71em 0 0.72em, 2.29em 0 0 1.43em, 0px 2.29em 0 1.43em, -0.35em 0.36em 0 0.72em, 1.43em -0.85em 0 0.79em;
    }
}
@-moz-keyframes checkbox-on {
    0% {
        box-shadow: 0 0 0 0.72em, 0.72em -0.71em 0 0.72em, 2.29em 0 0 1.43em, 0px 2.29em 0 1.43em, -0.35em 0.36em 0 0.72em, 1.08em 0.15em 0 0.79em;
    }
    50% {
        box-shadow: 0 0 0 0.72em, 0.72em -0.71em 0 0.72em, 2.29em 0 0 1.43em, 0px 2.29em 0 1.43em, -0.35em 0.36em 0 0.72em, 1.43em 0.15em 0 0.79em;
    }
    100% {
        box-shadow: 0 0 0 0.72em, 0.72em -0.71em 0 0.72em, 2.29em 0 0 1.43em, 0px 2.29em 0 1.43em, -0.35em 0.36em 0 0.72em, 1.43em -0.85em 0 0.79em;
    }
}
@-o-keyframes checkbox-on {
    0% {
        box-shadow: 0 0 0 0.72em, 0.72em -0.71em 0 0.72em, 2.29em 0 0 1.43em, 0px 2.29em 0 1.43em, -0.35em 0.36em 0 0.72em, 1.08em 0.15em 0 0.79em;
    }
    50% {
        box-shadow: 0 0 0 0.72em, 0.72em -0.71em 0 0.72em, 2.29em 0 0 1.43em, 0px 2.29em 0 1.43em, -0.35em 0.36em 0 0.72em, 1.43em 0.15em 0 0.79em;
    }
    100% {
        box-shadow: 0 0 0 0.72em, 0.72em -0.71em 0 0.72em, 2.29em 0 0 1.43em, 0px 2.29em 0 1.43em, -0.35em 0.36em 0 0.72em, 1.43em -0.85em 0 0.79em;
    }
}
@keyframes checkbox-on {
    0% {
        box-shadow: 0 0 0 0.72em, 0.72em -0.71em 0 0.72em, 2.29em 0 0 1.43em, 0px 2.29em 0 1.43em, -0.35em 0.36em 0 0.72em, 1.08em 0.15em 0 0.79em;
    }
    50% {
        box-shadow: 0 0 0 0.72em, 0.72em -0.71em 0 0.72em, 2.29em 0 0 1.43em, 0px 2.29em 0 1.43em, -0.35em 0.36em 0 0.72em, 1.43em 0.15em 0 0.79em;
    }
    100% {
        box-shadow: 0 0 0 0.72em, 0.72em -0.71em 0 0.72em, 2.29em 0 0 1.43em, 0px 2.29em 0 1.43em, -0.35em 0.36em 0 0.72em, 1.43em -0.85em 0 0.79em;
    }
}
@-webkit-keyframes rippleOn {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 0.2;
    }
    100% {
        opacity: 0;
    }
}
@-moz-keyframes rippleOn {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 0.2;
    }
    100% {
        opacity: 0;
    }
}
@-o-keyframes rippleOn {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 0.2;
    }
    100% {
        opacity: 0;
    }
}
@keyframes rippleOn {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 0.2;
    }
    100% {
        opacity: 0;
    }
}
@-webkit-keyframes rippleOff {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 0.2;
    }
    100% {
        opacity: 0;
    }
}
@-moz-keyframes rippleOff {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 0.2;
    }
    100% {
        opacity: 0;
    }
}
@-o-keyframes rippleOff {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 0.2;
    }
    100% {
        opacity: 0;
    }
}
@keyframes rippleOff {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 0.2;
    }
    100% {
        opacity: 0;
    }
}
.c-radio {
    cursor: pointer;
}
.c-radio label {
    cursor: pointer;
    position: relative;
    color: #666666;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
}
.c-radio [class^="c-radio__"] {
    -webkit-transition-duration: 0.2s;
    transition-duration: 0.2s;
}
.c-radio__circle {
    border: 1px solid #666666;
    width: 1.08em;
    height: 1.08em;
    border-radius: 100%;
    margin-right: 0.36em;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
}
.c-radio__check {
    width: 1.08em;
    height: 1.08em;
    border-radius: 100%;
    background-color: #00bbff;
    -webkit-transform: scale3d(0, 0, 0);
    -moz-transform: scale3d(0, 0, 0);
    -o-transform: scale3d(0, 0, 0);
    -ms-transform: scale3d(0, 0, 0);
    transform: scale3d(0, 0, 0);
}
.c-radio__check:after {
    content: "";
    display: block;
    position: absolute;
    top: 0px;
    left: 0px;
    background-color: rgba(0, 0, 0, 0.84);
    width: 1.08em;
    height: 1.08em;
    border-radius: 100%;
    z-index: 1;
    opacity: 0;
    margin: 0;
    -webkit-transform: scale3d(4, 4, 1);
    -moz-transform: scale3d(4, 4, 1);
    -o-transform: scale3d(4, 4, 1);
    -ms-transform: scale3d(4, 4, 1);
    transform: scale3d(4, 4, 1);
}
.c-radio input[type=radio] {
    opacity: 0;
    width: 0;
    height: 0;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
}
.c-radio input[type=radio]:checked~.c-radio__check, .c-radio input[type=radio]:checked~.c-radio__circle {
    opacity: 1;
}
.c-radio input[type=radio]:checked~.c-radio__check {
    background-color: #00bbff;
}
.c-radio input[type=radio]:checked~.c-radio__circle {
    border-color: #00bbff;
}
.c-radio input[type=radio]:checked+.c-radio__check::after {
    -webkit-animation: rippleOn 500ms;
    -o-animation: rippleOn 500ms;
    animation: rippleOn 500ms;
}
.c-radio input[type=radio]:checked+.c-radio__circle .c-radio__check {
    -webkit-transform: scale3d(0.65, 0.65, 1);
    -moz-transform: scale3d(0.65, 0.65, 1);
    -o-transform: scale3d(0.65, 0.65, 1);
    -ms-transform: scale3d(0.65, 0.65, 1);
    transform: scale3d(0.65, 0.65, 1);
}
.c-radio input[type=radio]:checked+.c-radio__circle .c-radio__check::after {
    -webkit-animation: rippleOn 500ms;
    -o-animation: rippleOn 500ms;
    animation: rippleOn 500ms;
}
.c-radio input[type=radio][disabled]~.c-radio__check, .c-radio input[type=radio][disabled]~.c-radio__circle {
    opacity: 0.26;
}
.c-radio input[type=radio][disabled]~.c-radio__check {
    background-color: #000000;
}
.c-radio input[type=radio][disabled]~.c-radio__circle {
    border-color: #000000;
}
@-webkit-keyframes rippleOn {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 0.2;
    }
    100% {
        opacity: 0;
    }
}
@-moz-keyframes rippleOn {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 0.2;
    }
    100% {
        opacity: 0;
    }
}
@-o-keyframes rippleOn {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 0.2;
    }
    100% {
        opacity: 0;
    }
}
@keyframes rippleOn {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 0.2;
    }
    100% {
        opacity: 0;
    }
}
[class*="c-icon"] {
    display: inline-block;
    position: relative;
    font-size: 13px;
    width: 1.54em;
    height: 1.54em;
    color: #999999;
    border-radius: 3px;
    -webkit-transition-duration: 0.2s;
    transition-duration: 0.2s;
}
[class*="c-icon"] i {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}
[class*="c-icon"]:hover {
    opacity: 0.7;
}
[class*="c-icon-bg"] {
    background-color: #999999;
    color: #FFFFFF;
}
[class*="c-icon-bg"]+[class*="c-icon-bg"] {
    margin-left: 5px;
}
[class*="c-icon-border"] {
    border: 1px solid #CCCCCC;
}
[class*="c-icon-border"]+[class*="c-icon-border"] {
    margin-left: 5px;
}
[class*="c-icon-img"] {
    background-size: 0.7em;
    background-repeat: no-repeat;
    background-position: center;
}
.c-icon--fb {
    color: #365899;
}
.c-icon--gl {
    color: #dd4b39;
}
.c-icon--tw {
    color: #55acee;
}
.c-icon--pr {
    color: #D63633;
}
.c-icon--ins {
    color: #cc2127;
}
.c-icon--yt {
    color: #e52d27;
}
.c-icon--in {
    color: #0976b4;
}
.c-icon--zalo {
    color: #0180C6;
}
.c-icon--skype {
    color: #00AFF0;
}
.c-icon--white {
    color: #FFFFFF;
}
.c-icon--primary {
    color: #00bbff;
}
.c-icon--secondary {
    color: #ffbf00;
}
.c-icon-circle {
    border-radius: 100%;
}
.c-icon-border--fb {
    border-color: #365899;
}
.c-icon-border--gl {
    border-color: #dd4b39;
}
.c-icon-border--tw {
    border-color: #55acee;
}
.c-icon-border--pr {
    border-color: #D63633;
}
.c-icon-border--ins {
    border-color: #cc2127;
}
.c-icon-border--yt {
    border-color: #e52d27;
}
.c-icon-border--in {
    border-color: #0976b4;
}
.c-icon-border--zalo {
    border-color: #0180C6;
}
.c-icon-border--skype {
    border-color: #00AFF0;
}
.c-icon-border--white {
    border-color: #FFFFFF;
}
.c-icon-border--primary {
    border-color: #00bbff;
}
.c-icon-border--secondary {
    border-color: #ffbf00;
}
.c-icon-border--fb {
    color: #365899;
}
.c-icon-border--gl {
    color: #dd4b39;
}
.c-icon-border--tw {
    color: #55acee;
}
.c-icon-border--pr {
    color: #D63633;
}
.c-icon-border--ins {
    color: #cc2127;
}
.c-icon-border--yt {
    color: #e52d27;
}
.c-icon-border--in {
    color: #0976b4;
}
.c-icon-border--zalo {
    color: #0180C6;
}
.c-icon-border--skype {
    color: #00AFF0;
}
.c-icon-border--white {
    color: #FFFFFF;
}
.c-icon-border--primary {
    color: #00bbff;
}
.c-icon-border--secondary {
    color: #ffbf00;
}
.c-icon-bg--fb {
    background-color: #365899;
}
.c-icon-bg--gl {
    background-color: #dd4b39;
}
.c-icon-bg--tw {
    background-color: #55acee;
}
.c-icon-bg--pr {
    background-color: #D63633;
}
.c-icon-bg--ins {
    background-color: #cc2127;
}
.c-icon-bg--yt {
    background-color: #e52d27;
}
.c-icon-bg--in {
    background-color: #0976b4;
}
.c-icon-bg--zalo {
    background-color: #0180C6;
}
.c-icon-bg--skype {
    background-color: #00AFF0;
}
.c-icon-bg--white {
    background-color: #FFFFFF;
}
.c-icon-bg--primary {
    background-color: #00bbff;
}
.c-icon-bg--secondary {
    background-color: #ffbf00;
}
.c-icon-img--fb {
    background-color: #365899;
}
.c-icon-img--gl {
    background-color: #dd4b39;
}
.c-icon-img--tw {
    background-color: #55acee;
}
.c-icon-img--pr {
    background-color: #D63633;
}
.c-icon-img--ins {
    background-color: #cc2127;
}
.c-icon-img--yt {
    background-color: #e52d27;
}
.c-icon-img--in {
    background-color: #0976b4;
}
.c-icon-img--zalo {
    background-color: #0180C6;
}
.c-icon-img--skype {
    background-color: #00AFF0;
}
.c-icon-img--white {
    background-color: #FFFFFF;
}
.c-icon-img--primary {
    background-color: #00bbff;
}
.c-icon-img--secondary {
    background-color: #ffbf00;
}
.c-icon-img--fb {
    border-color: #365899;
}
.c-icon-img--gl {
    border-color: #dd4b39;
}
.c-icon-img--tw {
    border-color: #55acee;
}
.c-icon-img--pr {
    border-color: #D63633;
}
.c-icon-img--ins {
    border-color: #cc2127;
}
.c-icon-img--yt {
    border-color: #e52d27;
}
.c-icon-img--in {
    border-color: #0976b4;
}
.c-icon-img--zalo {
    border-color: #0180C6;
}
.c-icon-img--skype {
    border-color: #00AFF0;
}
.c-icon-img--white {
    border-color: #FFFFFF;
}
.c-icon-img--primary {
    border-color: #00bbff;
}
.c-icon-img--secondary {
    border-color: #ffbf00;
}
.c-icon--sm {
    font-size: 1rem;
}
.c-icon--lg {
    font-size: 1.5rem;
}
.modal-backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1030;
    background-color: #000000;
    opacity: 0;
    -webkit-transition: opacity 0.15s linear;
    -o-transition: opacity 0.15s linear;
    transition: opacity 0.15s linear;
}
.modal-backdrop.show {
    opacity: 0.5;
}
.modal {
    font-size: 1.4rem;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1040;
    display: none;
    overflow: auto;
    opacity: 0;
    -webkit-transition: opacity 0.15s linear;
    -o-transition: opacity 0.15s linear;
    transition: opacity 0.15s linear;
}
.modal-box {
    max-width: 350px;
    padding: 4.29em 0.72em 0.72em 0.72em;
    -webkit-transform: translate(0, -25%);
    -ms-transform: translate(0, -25%);
    -o-transform: translate(0, -25%);
    transform: translate(0, -25%);
    -webkit-transition: transform 0.3s ease-out;
    -o-transition: transform 0.3s ease-out;
    transition: transform 0.3s ease-out;
    position: relative;
    z-index: 1050;
}
.modal.show {
    opacity: 1;
}
.modal.show .modal-box {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
}
.modal .close {
    font-size: 16px;
    cursor: pointer;
    -webkit-transition: 0.2s;
    -o-transition: 0.2s;
    transition: 0.2s;
    position: absolute;
    top: -1px;
    right: -1px;
    width: 1.79em;
    height: 2.15em;
    text-align: center;
    line-height: 2.15em;
}
.modal .close:hover {
    background-color: #ffbf00;
    border-radius: 0 0.43em 0 0.43em;
    color: #FFFFFF;
}
.modal-group {
    position: relative;
    background-color: #FFFFFF;
    border-radius: 0.43em;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
}
.modal__header {
    padding: 1.08em;
    border-radius: 0.43em 0.43em 0 0;
}
.modal__body {
    padding: 15px 20px;
}
.modal__footer {
    padding: 1.08em 1.08em 1.08em 1.08em;
    text-align: center;
}